import React from 'react'

import './styles.scss'

const Choice = ({ handleMakeChoice, nextId, text }) => {
	return (
		<div className="choice" onClick={() => handleMakeChoice(nextId)}>
			{text}
		</div>
	)
}

export { Choice }
