import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'

import { Offer } from '../../components/discount/offer'
import { Fantasy, Footer, Modal } from '../../components/sale'
import { ampEvent } from '../../utils/amplitude'
import { QueryHandler } from '../../utils/queryHandlers'

import './styles.scss'

const DiscountScreen = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { search } = useLocation()
	const queryParams = new URLSearchParams(search)

	const { email } = useSelector(state => state.user)
	const [isActiveModal, setIsActiveModal] = useState(false)

	const handleOpenModal = () => {
		setIsActiveModal(true)
		ampEvent('Checkout_shown')
	}

	const handleCloseModal = () => setIsActiveModal(false)

	useEffect(() => {
		QueryHandler(search, {
			setIsActiveModal,
			dispatch,
			history,
		})

		setTimeout(() => {
			ampEvent('Discount_screen_shown')
			if (queryParams.has('modal')) {
				ampEvent('Checkout_shown')
			}
		}, 0)
	}, [])

	return (
		<div className="discount">
			<Offer onBtnClick={handleOpenModal} />
			<Fantasy onBtnClick={handleOpenModal} />
			<Footer />
			<Modal
				total={0}
				amount={0}
				handleCloseModal={handleCloseModal}
				isActiveModal={isActiveModal}
				email={email}
				product_id={process.env.REACT_APP_DISCOUNT_PRODUCT_ID}
			/>
		</div>
	)
}

export { DiscountScreen }
