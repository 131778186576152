import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

import './Checkout.scss'
import PaymentForm from './CreditCard'

function Checkout(props) {
	return (
		<>
			<div className="checkout">
				<img
					src="/img/arrow.png"
					className="header__menu"
					style={{
						width: 23,
						height: 15,
						position: 'absolute',
						top: 25,
						left: 30,
					}}
					alt="img"
					onClick={() => props.history.goBack()}
				/>
				<p className="checkout__title">Checkout</p>
				<div className="amount__bill" style={{ margin: 0, width: '90%' }}>
					<div className="bill-row">
						<p className="bill-row__description">Your total is:</p>
						<p className="bill-row__price">$9,99</p>
					</div>
					<img src="/img/line.png" style={{ width: '100%' }} />
				</div>

				<div className="card-info">
					<PaymentForm />
					<p className="secured" style={{ marginTop: 60 }}>
						Your payment is secured
					</p>
				</div>
			</div>
		</>
	)
}

export default connect(
	state => ({ state }),
	dispatch => ({}),
)(withRouter(Checkout))
