const SECONDS_PER_MINUTE = 60

const formatTimeString = secondsLeft => {
	const minutes = Math.floor(secondsLeft / SECONDS_PER_MINUTE)
	const seconds = secondsLeft % SECONDS_PER_MINUTE

	return `${minutes < 10 ? '0' : ''}${minutes}m ${
		seconds < 10 ? '0' : ''
	}${seconds}s`
}

export default formatTimeString
