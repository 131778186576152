import React from 'react'

import './styles.scss'

const Footer = ({ checkboxes, flow, onClickCheckboxes }) => {
	return (
		<>
			{flow ? (
				<div className="email-footer">
					<span className="footer-text">
						By continuing you indicate that you’ve read and agree to our{' '}
					</span>
					<span className="footer-text">
						<a href="/terms-of-use" className="text-link">
							Terms & Conditions
						</a>
						,{' '}
						<a href="/privacy-policy" className="text-link">
							Privacy Policy
						</a>
						, and{' '}
						<a href="/subscription-terms" className="text-link">
							Subscription Terms
						</a>
					</span>
				</div>
			) : (
				<div className="email-footer-privacies">
					<div className="privacy">
						<div
							className={`privacy-checkbox ${
								checkboxes[0].isChecked ? 'privacy-checkbox--checked' : ''
							}`}
							onClick={() => onClickCheckboxes(0)}
						>
							<img
								src="/img/email/check-icon.png"
								alt="privacy-checkbox"
								className="privacy-checkbox__image"
							/>
						</div>
						<p className="privacy-text">
							By continuing I agree with{' '}
							<a href="/terms-of-use" className="privacy-text__link">
								Terms of Service
							</a>
							,{' '}
							<a href="/privacy-policy" className="privacy-text__link">
								Privacy Policy
							</a>
							,{' '}
							<a href="/money-back" className="privacy-text__link">
								Money-Back Policy
							</a>
							,{' '}
							<a href="/subscription-terms" className="privacy-text__link">
								Subscription terms
							</a>
							,{' '}
							<a href="/privacy-policy" className="privacy-text__link">
								Cookie policy
							</a>
						</p>
					</div>
					<div className="privacy">
						<div
							className={`privacy-checkbox ${
								checkboxes[1].isChecked ? 'privacy-checkbox--checked' : ''
							}`}
							onClick={() => onClickCheckboxes(1)}
						>
							<img
								src="/img/email/check-icon.png"
								alt="privacy-checkbox"
								className="privacy-checkbox__image"
							/>
						</div>
						<p className="privacy-text">
							I would like to receive updates about products, services, and
							special offers from My Fantasy via email
						</p>
					</div>
				</div>
			)}
		</>
	)
}

export { Footer }
