const initialState = {
	choises: [
		{
			id: 0,
			componentType: 'SLIDER_ASSETS',
			sliderTemplates: ['female', 'male'],
			question: 'Select your gender',
			tag: 'gender',
			selected: 'female',
			answered: false,
			buttonText: 'Confirm',
		},
		{
			id: 1,
			componentType: 'SLIDER_ASSETS',
			sliderTemplates: ['body1', 'body2', 'body3', 'body4', 'body5', 'body6'],
			question: 'Choose your type',
			tag: 'body',
			selected: 'body1',
			answered: false,
			buttonType: 'Confirm',
		},
		{
			id: 2,
			componentType: 'SLIDER_ASSETS',
			sliderTemplates: ['hair1', 'hair2', 'hair3', 'hair4', 'hair5', 'hair6'],
			question: 'Choose your hair',
			tag: 'hair',
			selected: 'hair1',
			answered: false,
			buttonType: 'Confirm',
		},
		{
			id: 3,
			componentType: 'SLIDER_ASSETS',
			sliderTemplates: [
				'clothes1',
				'clothes2',
				'clothes3',
				'clothes4',
				'clothes5',
				'clothes6',
			],
			question: 'Choose your clothes',
			tag: 'clothes',
			selected: 'clothes1',
			answered: false,
			buttonType: 'Confirm',
		},
		{
			id: 4,
			componentType: 'NAME_INPUT',
			text: 'Choose your name',
			name: 'Foo',
			answered: false,
			buttonType: 'Confirm',
		},
		{
			id: 5,
			componentType: 'CHARACTER_QUIZ',
			selectors: [
				{
					header: 'The Go-getter',
					value: 'Independent, quirky and confident personality',
				},
				{
					header: 'The Temptress',
					value: 'Energetic, sexually driven and bold',
				},
				{
					header: 'The Charmer',
					value: 'Romantic and gentle soul who needs support ',
				},
				{
					header: 'The Adventurer',
					value: 'Creative, playful and free-spirited person',
				},
			],
			selected: null,
			question: 'Choose your character',
			answered: false,
			buttonType: 'Confirm',
		},
		{
			id: 0,
			componentType: 'SECOND_PERSON_ASSETS',
			sliderTemplates: ['female', 'male'],
			question: 'Choose your love interest gender',
			tag: 'gender',
			selected: 'male',
			answered: false,
			buttonText: 'Confirm',
		},
		{
			id: 6,
			componentType: 'SECOND_PERSON_ASSETS',
			sliderTemplates: ['body1', 'body2', 'body3', 'body4', 'body5', 'body6'],
			question: 'Choose your Love Interest',
			tag: 'body',
			selected: 'body1',
			answered: false,
			buttonType: 'Confirm',
		},
		{
			id: 7,
			componentType: 'SECOND_PERSON_ASSETS',
			sliderTemplates: ['hair1', 'hair2', 'hair3', 'hair4', 'hair5', 'hair6'],
			question: 'Choose their hairstyle',
			tag: 'hair',
			selected: 'hair1',
			answered: false,
			buttonType: 'Confirm',
		},
		{
			id: 8,
			componentType: 'SECOND_PERSON_ASSETS',
			sliderTemplates: [
				'clothes1',
				'clothes2',
				'clothes3',
				'clothes4',
				'clothes5',
				'clothes6',
			],
			question: 'Choose their clothes',
			tag: 'clothes',
			selected: 'clothes1',
			answered: false,
			buttonType: 'Confirm',
		},
		{
			id: 9,
			componentType: 'NAME_INPUT',
			text: 'Choose name for your LI',
			name: null,
			answered: false,
			buttonType: 'Confirm',
		},
		{
			id: 10,
			componentType: 'CHARACTER_QUIZ',
			selectors: [
				{
					header: 'The Winner',
					value: 'A self-made man of fortune in search of equal partner',
				},
				{
					header: 'The Warrior',
					value: 'Brave, strong, muscled protector of the weak',
				},
				{
					header: 'The Romantic',
					value: 'Sweet and honest person, who will never let you down',
				},
				{
					header: 'The Bad Boy',
					value: 'A dangerous and notorious type with a kind heart',
				},
			],
			selected: null,
			question: 'Choose character for your Love Interest',
			answered: false,
			buttonType: 'Confirm',
		},
		{
			id: 11,
			componentType: 'BACKGROUND_SELECT',
			sliderTemplates: [
				'background123',
				'background2',
				'background3',
				'background4',
			],
			selected: 'background123',
			question: 'Choose your ideal atmosphere',
			answered: false,
			buttonType: 'Confirm',
		},
		{
			id: 11,
			componentType: 'BACKGROUND_AND_TITLE_SELECT',
			sliderTemplates: [
				{
					background: 'background5',
					title: 'Romantic evening in a jacuzzi with champagne',
				},
				{
					background: 'background6',
					title: 'A steamy getaway in a luxurious hotel',
				},
				{
					background: 'background7',
					title: 'A dinner with wine in a cozy restaurant',
				},
				{
					background: 'background8',
					title: 'A crazy night of dancing in a club',
				},
			],
			selected: {
				background: 'background5',
				title: 'Romantic evening in a jacuzzi with champagne',
			},
			question: 'Choose your dream date',
			answered: false,
			characters: true,
			buttonType: 'Confirm',
		},
		{
			id: 11,
			componentType: 'BACKGROUND_AND_TITLE_SELECT',
			sliderTemplates: [
				{ background: 'background9', title: 'Bahama Islands, USA' },
				{ background: 'background10', title: 'Walland, Tennessee' },
				{ background: 'background11', title: 'Paris, France' },
				{ background: 'background12', title: 'Venice, Italy' },
			],
			selected: { background: 'background9', title: 'Bahama Islands, USA' },
			question: 'Choose your perfect weekend',
			answered: false,
			characters: true,
			buttonType: 'Confirm',
		},
		{
			id: 11,
			componentType: 'BACKGROUND_AND_TITLE_SELECT',
			sliderTemplates: [
				{ background: 'background13', title: 'Impact play' },
				{ background: 'background14', title: 'Group sex' },
				{ background: 'background15', title: 'Sensory deprivation' },
				{ background: 'background16', title: 'Sex in a shower' },
			],
			selected: { background: 'background13', title: 'Impact play' },
			question: 'Choose your sexual fantasy',
			answered: false,
			characters: false,
			buttonType: 'Confirm',
		},
		// {
		// 	id: 11,
		// 	componentType: 'BOOK_SELECT',
		// 	sliderTemplates: ['book1', 'book2', 'book3', 'book4'],
		// 	selected: 'book1',
		// 	question: 'Choose your favorite part of books/comics',
		// 	answered: false,
		// 	buttonType: 'Confirm',
		// },
		// {
		// 	id: 11,
		// 	componentType: 'BOOK_SELECT',
		// 	sliderTemplates: ['book5', 'book6', 'book7', 'book8', 'book9', 'book10'],
		// 	selected: 'book5',
		// 	question: 'Choose the book you like the most',
		// 	answered: false,
		// 	buttonType: 'Confirm',
		// },
		{
			id: 11,
			componentType: 'MARRIAGE_QUESTION',
			question: 'Choose your answer',
			personQuestion: 'Are you hinting a marriage?',
			answer: ['Oh hello no!No no no!', `I'm in, set thre date!`],
			answered: false,
		},
		{
			id: 11,
			componentType: 'MARRIAGE_ANSWER',
			question: 'Explore your book',
			answer: false,
			buttonType: 'To be continued',
			answered: false,
		},
		// {
		// 	componentType: 'PHONE',
		// 	question: 'Explore your book',
		// 	answered: false,
		// },
	],
}

export default initialState
