export const SET_SELECT = 'SET_SELECT'
export const NEW_GAME = 'NEW_GAME'
export const NEXT_SELECT = 'NEXT_SELECT'
export const SET_QUIZ_SELECT = 'SET_QUIZ_SELECT'
export const SET_NAME = 'SET_NAME'
export const PREV_SELECT = 'PREV_SELECT'
export const SET_BOOK = 'SET_BOOK'
export const SET_ANSWER = 'SET_ANSWER'

export const setSelect = payload => ({ type: SET_SELECT, payload })

export const newGame = () => ({ type: NEW_GAME })

export const nextSelect = payload => ({ type: NEXT_SELECT, payload })

export const setQuizSelect = payload => ({ type: SET_QUIZ_SELECT, payload })

export const setName = payload => ({ type: SET_NAME, payload })

export const prevSelect = payload => ({ type: PREV_SELECT, payload })

export const setBook = payload => ({ type: SET_BOOK, payload })

export const setAnswer = payload => ({ type: SET_ANSWER, payload })
