import React, { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { Button } from '../../components/common'
import { ampEvent } from '../../utils/amplitude'

import './styles.scss'

const ErrorScreen = () => {
	const { state, pathname } = useLocation()

	const { email, error, flow } = useSelector(state => state.user)
	const history = useHistory()

	useEffect(() => {
		ampEvent('Error_screen')
	}, [])

	const buttonHandler = () => {
		if (!state) {
			if (flow) {
				return history.push(
					`/sale?flow=${flow}&email=${decodeURIComponent(
						email,
					)}&message=true&modal=true`,
				)
			} else {
				return history.push(
					`/sale?email=${decodeURIComponent(email)}&message=true&modal=true`,
				)
			}
		}
		if (state.from === '/sale') {
			if (flow) {
				return history.push({
					pathname: `/sale`,
					search: `?flow=${flow}&email=${decodeURIComponent(email)}&modal=true`,
					state: { from: pathname },
				})
			} else {
				return history.push({
					pathname: `/sale`,
					search: `?email=${decodeURIComponent(email)}&modal=true`,
					state: { from: pathname },
				})
			}
		}
		if (state.from === '/discount') {
			if (flow) {
				return history.push({
					pathname: `/discount`,
					search: `?flow=${flow}&email=${decodeURIComponent(email)}&modal=true`,
					state: { from: pathname },
				})
			} else {
				return history.push({
					pathname: `/discount`,
					search: `?email=${decodeURIComponent(email)}&modal=true`,
					state: { from: pathname },
				})
			}
		}
		if (flow) {
			return history.push(
				`/sale?flow=${flow}&email=${decodeURIComponent(
					email,
				)}&message=true&modal=true`,
			)
		} else {
			return history.push(
				`/sale?email=${decodeURIComponent(email)}&message=true&modal=true`,
			)
		}
	}

	return (
		<div className="error">
			<img
				src="/img/success/my-fantasy.png"
				alt="error"
				className="error__image"
			/>
			<h1 className="error__title">Ooops</h1>
			<p className="error__description">
				Sorry, your transaction failed.
				<br />
				{error}
			</p>
			<Button label="Try again" onClick={buttonHandler} />
		</div>
	)
}

export { ErrorScreen }
