import React from 'react'

import './styles.scss'

const Reviews = () => {
	return (
		<div className="sale-reviews">
			<h2 className="sale-reviews__title">Our customers love us! </h2>
			<img
				src="/img/sale/review1.png"
				alt="review1"
				className="sale-reviews__image"
			/>
			<img
				src="/img/sale/review2.png"
				alt="review2"
				className="sale-reviews__image"
			/>
			<img
				src="/img/sale/review3.png"
				alt="review3"
				className="sale-reviews__image"
			/>
		</div>
	)
}

export { Reviews }
