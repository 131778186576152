import React, { useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory } from 'react-router'

import { setEmail } from '../../../redux/actions/user'
import { ampSetUserProperty } from '../../../utils/amplitude'
import validateEmail from '../../../utils/validateEmail'
import { Button } from '../../common'
import { Footer } from '../../email'

import './styles.scss'

const ENTER_KEYCODE = 13

const Form = () => {
	const dispatch = useDispatch()
	const history = useHistory()
	const { flow } = useSelector(state => state.user)

	const [formEmail, setFormEmail] = useState('')
	const [checkboxes, setCheckboxes] = useState([
		{
			isChecked: true,
		},
		{
			isChecked: true,
		},
	])

	const isValidEmail = validateEmail(formEmail)

	const handleSubmitEmail = () => {
		dispatch(setEmail({ email: formEmail }))
		ampSetUserProperty('email', formEmail)
		if (flow) {
			history.push(`/sale?flow=${flow}&email=${formEmail}&message=false`)
		} else {
			history.push(`/sale?email=${formEmail}&message=false`)
		}
	}

	const isSelectedAllCheckboxes = !checkboxes[0].isChecked
	const handleKeyboardSubmitEmail = e => {
		const { keyCode } = e
		if (keyCode === ENTER_KEYCODE && isValidEmail) {
			handleSubmitEmail()
		}
	}

	const onClickCheckboxes = idx =>
		setCheckboxes(
			checkboxes.map((checkbox, index) => {
				if (idx === index) {
					checkbox.isChecked = !checkbox.isChecked
				}
				return checkbox
			}),
		)
	return (
		<div className="email-form">
			<h2 className="email-form__title">
				{!flow || flow === '11'
					? 'Choose your own story!'
					: 'Choose your own story!'}
			</h2>
			<p className="email-form__description">
				{!flow || flow === '11'
					? 'Enter email to get access to many stories and full satisfaction'
					: 'Enter email to get many stories and full satisfaction'}
			</p>
			<input
				type="email"
				className="email-form__input"
				onChange={e => setFormEmail(e.target.value)}
				onKeyDown={e => handleKeyboardSubmitEmail(e)}
				placeholder="somepost@gmail.com"
			/>
			<div
				className={`email-form-button-container ${!(
					isValidEmail && !isSelectedAllCheckboxes
				) ?'disabled' : ''}`}
			>
				<Button
					label="Continue"
					onClick={handleSubmitEmail}
					disabled={!(isValidEmail && !isSelectedAllCheckboxes)}
				/>
			</div>
			<Footer
				flow={flow}
				onClickCheckboxes={onClickCheckboxes}
				checkboxes={checkboxes}
			/>
		</div>
	)
}

export { Form }
