import React from 'react'
import { useSelector } from 'react-redux'

import './styles.scss'

const Media = () => {
	const { flow } = useSelector(state => state.user)

	const downloadMediaByFlow = () => {
		switch (flow) {
			case '2': {
				return (
					<>
						<img
							src="/img/story/backgrounds/mmy-mc-bedroom-1.jpg"
							alt="/img/story/backgrounds/mmy-mc-bedroom-1.jpg"
							className="none-media"
						/>
						<img
							src="/img/story/MAINHERO/joy.png"
							alt="/img/story/MAINHERO/joy.png"
							className="none-media"
						/>
						<img
							src="/img/story/MAINHERO/default.png"
							alt="/img/story/MAINHERO/default.png"
							className="none-media"
						/>
						<img
							src="/img/story/MAINHERO/sad.png"
							alt="/img/story/MAINHERO/sad.png"
							className="none-media"
						/>
						<img
							src="/img/story/MAINHERO/shy.png"
							alt="/img/story/MAINHERO/shy.png"
							className="none-media"
						/>
						<img
							src="/img/story/MAINHERO/surprised.png"
							alt="/img/story/MAINHERO/surprised.png"
							className="none-media"
						/>
						<img
							src="/img/story/backgrounds/mmy-club-stage-2.jpg"
							alt="/img/story/backgrounds/mmy-club-stage-2.jpg"
							className="none-media"
						/>
						<img
							src="/img/story/Cathy/joy.png"
							alt="/img/story/Cathy/joy.png"
							className="none-media"
						/>
						<img
							src="/img/story/Cathy/default.png"
							alt="/img/story/Cathy/default.png"
							className="none-media"
						/>
						<img
							src="/img/story/Lewis/default.png"
							alt="/img/story/Lewis/default.png"
							className="none-media"
						/>
						<img
							src="/img/story/backgrounds/mmy-club-play-area-3.jpg"
							alt="/img/story/backgrounds/mmy-club-play-area-3.jpg"
							className="none-media"
						/>
						<img
							src="/img/story/Brett/default.png"
							alt="/img/story/Brett/default.png"
							className="none-media"
						/>
					</>
				)
			}

			case '1':
			default: {
				return (
					<>
						<img
							src="/assets/backgrounds/background1.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/body/body1.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/body/body2.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/body/body3.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/body/body4.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/body/body5.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/body/body6.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/clothes/clothes1.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/clothes/clothes2.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/clothes/clothes3.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/clothes/clothes4.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/clothes/clothes5.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/clothes/clothes6.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/faces/body1.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/faces/body2.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/faces/body3.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/faces/body4.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/faces/body5.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/faces/body6.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/hair/hair1.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/hair/hair2.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/hair/hair3.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/hair/hair4.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/hair/hair5.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/hair/hair6.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/hair/hair1back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/hair/hair2back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/hair/hair3back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/hair/hair4back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/hair/hair5back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/female/hair/hair6back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/body/body1.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/body/body2.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/body/body3.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/body/body4.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/body/body5.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/body/body6.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/clothes/clothes1.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/clothes/clothes2.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/clothes/clothes3.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/clothes/clothes4.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/clothes/clothes5.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/clothes/clothes6.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/faces/body1.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/faces/body2.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/faces/body3.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/faces/body4.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/faces/body5.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/faces/body6.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/hair/hair1.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/hair/hair2.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/hair/hair3.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/hair/hair4.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/hair/hair5.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/hair/hair6.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/hair/hair1back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/hair/hair2back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/hair/hair3back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/hair/hair4back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/hair/hair5back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/loveinterest/male/hair/hair6back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/body/body1.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/body/body2.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/body/body3.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/body/body4.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/body/body5.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/body/body6.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/clothes/clothes1.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/clothes/clothes2.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/clothes/clothes3.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/clothes/clothes4.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/clothes/clothes5.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/clothes/clothes6.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/faces/body1.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/faces/body2.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/faces/body3.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/faces/body4.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/faces/body5.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/faces/body6.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/hair/hair1.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/hair/hair2.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/hair/hair3.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/hair/hair4.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/hair/hair5.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/hair/hair6.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/hair/hair1back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/hair/hair2back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/hair/hair3back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/hair/hair4back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/hair/hair5back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/female/hair/hair6back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/body/body1.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/body/body2.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/body/body3.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/body/body4.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/body/body5.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/body/body6.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/clothes/clothes1.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/clothes/clothes2.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/clothes/clothes3.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/clothes/clothes4.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/clothes/clothes5.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/clothes/clothes6.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/faces/body1.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/faces/body2.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/faces/body3.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/faces/body4.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/faces/body5.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/faces/body6.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/hair/hair1.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/hair/hair2.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/hair/hair3.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/hair/hair4.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/hair/hair5.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/hair/hair6.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/hair/hair1back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/hair/hair2back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/hair/hair3back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/hair/hair4back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/hair/hair5back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/mainhero/male/hair/hair6back.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/backgrounds/background2.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/backgrounds/background3.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/backgrounds/background4.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/backgrounds/background5.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/backgrounds/background6.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/backgrounds/background7.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/backgrounds/background8.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/backgrounds/background9.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/backgrounds/background10.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/backgrounds/background11.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/backgrounds/background12.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/backgrounds/background13.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/backgrounds/background14.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/backgrounds/background15.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/backgrounds/background16.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/backgrounds/background123.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/arrows/arrowLeft.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/arrows/arrowRight.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/books/book1.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/books/book2.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/books/book3.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/books/book4.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/books/book5.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/books/book6.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/books/book7.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/books/book8.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/books/book9.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/books/book10.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/home/slide1.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/home/slide2.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/home/slide3.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/home/slide4.png"
							className="image-none abs-under"
						/>
						<img
							src="/assets/home/slide5.png"
							className="image-none abs-under"
						/>
					</>
				)
			}
		}
	}

	return (
		<>
			{downloadMediaByFlow()}
			<img
				src="/img/email/logo.png"
				alt="/img/email/logo.png"
				className="none-media"
			/>
			<img
				src="/img/email/bg.png"
				alt="/img/email/bg.png"
				className="none-media"
			/>
			<img
				src="/img/sale/logo.png"
				alt="/img/sale/logo.png"
				className="none-media"
			/>
			<img
				src="/img/sale/come-in-icon.png"
				alt="/img/sale/come-in-icon.png"
				className="none-media"
			/>
			<img
				src="/img/sale/lock.png"
				alt="/img/sale/lock.png"
				className="none-media"
			/>
			<img
				src="/img/sale/phone-frame.png"
				alt="/img/sale/phone-frame.png"
				className="none-media"
			/>
			<video
				src="/video/sale/fantasy.mp4"
				alt="/video/sale/fantasy.mp4"
				className="none-media"
			/>
			<img
				src="/img/sale/dark-room.png"
				alt="/img/sale/dark-room.png"
				className="none-media"
			/>
			<img
				src="/img/sale/spicy-story.png"
				alt="/img/sale/spicy-story.png"
				className="none-media"
			/>
			<img
				src="/img/sale/diamond.png"
				alt="/img/sale/diamond.png"
				className="none-media"
			/>
			<img
				src="/img/sale/vip-pass.png"
				alt="/img/sale/vip-pass.png"
				className="none-media"
			/>
			<img
				src="/img/sale/unlocked.png"
				alt="/img/sale/unlocked.png"
				className="none-media"
			/>
			<img
				src="/img/sale/review1.png"
				alt="/img/sale/review1.png"
				className="none-media"
			/>
			<img
				src="/img/sale/review2.png"
				alt="/img/sale/review2.png"
				className="none-media"
			/>
			<img
				src="/img/sale/review3.png"
				alt="/img/sale/review3.png"
				className="none-media"
			/>
			<img
				src="/img/sale/ribbon.png"
				alt="/img/sale/ribbon.png"
				className="none-media"
			/>
			<img
				src="/img/sale/pay-safe-secure.png"
				alt="/img/sale/pay-safe-secure.png"
				className="none-media"
			/>
			<img
				src="/img/success/my-fantasy.png"
				alt="/img/success/my-fantasy.png"
				className="none-media"
			/>
			<img
				src="/img/success/download-apple.png"
				alt="/img/success/download-apple.png"
				className="none-media"
			/>
			<img
				src="/img/success/download-google.png"
				alt="/img/success/download-google.png"
				className="none-media"
			/>
			<img
				src="/img/success/step2.png"
				alt="/img/success/step2.png"
				className="none-media"
			/>
			<img
				src="/img/success/my-fantasy.png"
				alt="/img/success/my-fantasy.png"
				className="none-media"
			/>
		</>
	)
}

export { Media }
