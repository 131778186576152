import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import './MarriageQuestion.scss'
import { Link } from 'react-router-dom'

function MarriageQuestion(props) {
	return (
		<>
			<div className="characters-bottom" style={{ height: '103%' }}>
				<img
					className="characters-bottom__mainhero"
					src={`/assets/mainhero/${
						props.state.questions.choises[0].selected || 'female'
					}/hair/${props.state.questions.choises[2].selected}back.png`}
					alt="Первый слайд"
				/>
				<img
					className="characters-bottom__mainhero"
					src={`/assets/mainhero/${
						props.state.questions.choises[0].selected || 'female'
					}/body/${props.state.questions.choises[1].selected}.png`}
					alt="Первый слайд"
				/>
				<img
					className="characters-bottom__mainhero"
					src={`/assets/mainhero/${
						props.state.questions.choises[0].selected || 'female'
					}/clothes/${props.state.questions.choises[3].selected}.png`}
					alt="Первый слайд"
				/>
				<img
					className="characters-bottom__mainhero"
					src={`/assets/mainhero/${
						props.state.questions.choises[0].selected || 'female'
					}/faces/${props.state.questions.choises[1].selected}.png`}
					alt="Первый слайд"
				/>
				<img
					className="characters-bottom__mainhero"
					src={`/assets/mainhero/${
						props.state.questions.choises[0].selected || 'female'
					}/hair/${props.state.questions.choises[2].selected}.png`}
					alt="Первый слайд"
				/>
			</div>
			<div className="marriage">
				<div className="marriage__message">
					<div className="message__field">
						<p className="message__text_question" style={{}}>
							{props.state.questions.choises[4].name}
						</p>
						<img
							src="/assets/etc/Base.png"
							className="message__image_question"
						/>
					</div>
					<Link
						to={{
							pathname: `/questions/${
								parseInt(props.match.params.questionid) + 1
							}`,
						}}
						className="answer__field"
						onClick={() =>
							props.getAnswer({ id: props.match.params.questionid - 1 })
						}
					>
						<p className="answer1 answer__active">
							I’m in, set the date!
							<img src="/assets/etc/gem.png" />
						</p>
					</Link>
					<Link
						to={{
							pathname: `/questions/${
								parseInt(props.match.params.questionid) + 1
							}`,
						}}
						className="answer__field"
						onClick={() =>
							props.getAnswer({ id: props.match.params.questionid - 1 })
						}
					>
						<p className="answer1 answer__none">
							Oh hell no! No no no!
							<img src="/assets/etc/gem.png" className="image-none" />
						</p>
					</Link>
				</div>
			</div>
		</>
	)
}
export default connect(
	state => ({ state }),
	dispatch => ({
		getAnswer: payload => {
			dispatch({ type: 'SET_ANSWER', payload })
		},
	}),
)(withRouter(MarriageQuestion))
