import React from 'react'
import './Success.scss'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

function Success(props) {
	return (
		<div className="success">
			<img
				src={`/assets/personality/success/${
					props.state.questions.choises[19].answer || 2
				}.png`}
				className="premium__img"
				style={{ width: '100%', marginTop: 17 }}
			/>
			<p className="success__trans">Transaction was successful</p>
			<div style={{ marginTop: 24 }}>
				<span className="first-part">Your promo code for </span>
				<span className="second-part"> My Fantasy</span>
			</div>
			<div className="clipboard">
				<p
					className="clipboard__text"
					onClick={() => navigator.clipboard.writeText('574gAwe')}
				>
					574gAwe
				</p>
			</div>
			<label>Tap to copy</label>
			<p className="instruction">Instruction to the promo-code</p>
			<button className="appstore">Open App Store</button>
		</div>
	)
}
export default connect(
	state => ({ state }),
	dispatch => ({}),
)(withRouter(Success))
