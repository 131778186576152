import { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import './BookSelector.scss'
import { TaskBox } from '../exporter'
import { Spinner } from 'react-bootstrap'
import { Link } from 'react-router-dom'

function BookSelector(props) {
	const [loaded, setLoaded] = useState(
		props.state.questions.choises[props.match.params.questionid - 1]
			.sliderTemplates.length - 1,
	)
	const [count, setCount] = useState(0)
	const [visible, setVisible] = useState(false)

	const increment = () => {
		setLoaded(
			props.state.questions.choises[props.match.params.questionid - 1]
				.sliderTemplates.length - 1,
		)
		setCount(count + 1)
		if (count === loaded && count !== 0) {
			setVisible(true)
		}
	}

	const answer = () => {
		props.getAnswer({ id: props.match.params.questionid - 1 })
		setVisible(false)
		setCount(0)
	}
	return (
		<>
			<TaskBox />
			<a
				className="carousel-control-prev carousel-control__custom "
				href="#carouselExampleControls"
				role="button"
				data-slide="prev"
			>
				<span className="sr-only">Previous</span>
				<img src="/assets/arrows/arrowLeft.png" className="arrow left-arrow" />
			</a>
			<div
				id="carouselExampleControls"
				className="carousel slide carousel__book"
				data-ride="carousel"
			>
				<div className="carousel-inner carousel-inner__books">
					{props.state.questions.choises[
						props.match.params.questionid - 1
					].sliderTemplates.map((elem, index) => {
						return (
							<div
								key={index + Math.random()}
								className={
									index === 0
										? 'carousel-item active carousel-item__book'
										: 'carousel-item carousel-item__book'
								}
							>
								<Spinner
									animation="border"
									role="status"
									style={{ marginTop: '40%' }}
								>
									<span className="sr-only">Loading...</span>
								</Spinner>
								<img
									className={
										visible ? 'carousel__books' : 'carousel__books image-none'
									}
									onLoad={() => increment()}
									src={`/assets/books/${elem}.png`}
									style={{ width: 250, height: 400 }}
									alt="First slide"
								/>
							</div>
						)
					})}
				</div>
			</div>
			<a
				className="carousel-control-next carousel-control"
				href="#carouselExampleControls"
				role="button"
				data-slide="next"
			>
				<span className="sr-only">Next</span>
				<img
					src="/assets/arrows/arrowRight.png"
					className="arrow right-arrow"
				/>
			</a>
			<div className="button__position">
				<div className="question__control question__control__custom">
					<Link
						to={{
							pathname: `/questions/${
								parseInt(props.match.params.questionid) + 1
							}`,
						}}
						className="control__button"
						onClick={() => answer()}
					>
						Confirm
					</Link>
				</div>
			</div>
		</>
	)
}
export default connect(
	state => ({ state }),
	dispatch => ({
		newGame: () => {
			dispatch({ type: 'NEW_GAME' })
		},
		setSelect: payload => {
			dispatch({ type: 'SET_SELECT', payload })
		},
		nextSelect: payload => {
			dispatch({ type: 'NEXT_SELECT', payload })
		},
		prevSelect: payload => {
			dispatch({ type: 'PREV_SELECT', payload })
		},
		getAnswer: payload => {
			dispatch({ type: 'SET_ANSWER', payload })
		},
	}),
)(withRouter(BookSelector))
