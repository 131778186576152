import React from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'

function BottomPersons(props) {
	return (
		<div
			className="characters-bottom"
			style={{ marginBottom: -80, height: '100%' }}
		>
			<img
				className={
					props.visible
						? 'characters-bottom__mainhero'
						: 'characters-bottom__mainhero image-none'
				}
				src={`/assets/mainhero/${
					props.state.questions.choises[0].selected || 'female'
				}/hair/${props.state.questions.choises[2].selected}back.png`}
				alt="Первый слайд"
			/>
			<img
				className={
					props.visible
						? 'characters-bottom__mainhero'
						: 'characters-bottom__mainhero image-none'
				}
				src={`/assets/mainhero/${
					props.state.questions.choises[0].selected || 'female'
				}/body/${props.state.questions.choises[1].selected}.png`}
				alt="Первый слайд"
			/>
			<img
				className={
					props.visible
						? 'characters-bottom__mainhero'
						: 'characters-bottom__mainhero image-none'
				}
				src={`/assets/mainhero/${
					props.state.questions.choises[0].selected || 'female'
				}/clothes/${props.state.questions.choises[3].selected}.png`}
				alt="Первый слайд"
			/>
			<img
				className={
					props.visible
						? 'characters-bottom__mainhero'
						: 'characters-bottom__mainhero image-none'
				}
				src={`/assets/mainhero/${
					props.state.questions.choises[0].selected || 'female'
				}/faces/${props.state.questions.choises[1].selected}.png`}
				alt="Первый слайд"
			/>
			<img
				className={
					props.visible
						? 'characters-bottom__mainhero'
						: 'characters-bottom__mainhero image-none'
				}
				src={`/assets/mainhero/${
					props.state.questions.choises[0].selected || 'female'
				}/hair/${props.state.questions.choises[2].selected}.png`}
				alt="Первый слайд"
			/>
			<img
				className={
					props.visible
						? 'characters-bottom__loveinterest'
						: 'characters-bottom__loveinterest image-none'
				}
				src={`/assets/loveinterest/${
					props.state.questions.choises[6].selected || 'female'
				}/hair/${props.state.questions.choises[8].selected}back.png`}
				alt="Первый слайд"
			/>
			<img
				className={
					props.visible
						? 'characters-bottom__loveinterest'
						: 'characters-bottom__loveinterest image-none'
				}
				src={`/assets/loveinterest/${
					props.state.questions.choises[6].selected || 'female'
				}/body/${props.state.questions.choises[7].selected}.png`}
				alt="Первый слайд"
			/>
			<img
				className={
					props.visible
						? 'characters-bottom__loveinterest'
						: 'characters-bottom__loveinterest image-none'
				}
				src={`/assets/loveinterest/${
					props.state.questions.choises[6].selected || 'female'
				}/clothes/${props.state.questions.choises[9].selected}.png`}
				alt="Первый слайд"
			/>
			<img
				className={
					props.visible
						? 'characters-bottom__loveinterest'
						: 'characters-bottom__loveinterest image-none'
				}
				src={`/assets/loveinterest/${
					props.state.questions.choises[6].selected || 'female'
				}/faces/${props.state.questions.choises[7].selected}.png`}
				alt="Первый слайд"
			/>
			<img
				className={
					props.visible
						? 'characters-bottom__loveinterest'
						: 'characters-bottom__loveinterest image-none'
				}
				src={`/assets/loveinterest/${
					props.state.questions.choises[6].selected || 'female'
				}/hair/${props.state.questions.choises[8].selected}.png`}
				alt="Первый слайд"
			/>
		</div>
	)
}

export default connect(state => ({ state }))(withRouter(BottomPersons))
