import React, { useState } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import './MarriageAnswer.scss'

function MarriageQuestion(props) {
	const [number] = useState(Math.floor(Math.random() * 4) + 1)

	return (
		<>
			<div className="characters-bottom rotate" style={{ height: '103%' }}>
				<img
					className="characters-bottom__mainhero"
					src={`/assets/loveinterest/${
						props.state.questions.choises[6].selected || 'female'
					}/hair/${props.state.questions.choises[8].selected}back.png`}
					alt="Первый слайд"
				/>
				<img
					className="characters-bottom__mainhero"
					src={`/assets/loveinterest/${
						props.state.questions.choises[6].selected || 'female'
					}/body/${props.state.questions.choises[7].selected}.png`}
					alt="Первый слайд"
				/>
				<img
					className="characters-bottom__mainhero"
					src={`/assets/loveinterest/${
						props.state.questions.choises[6].selected || 'female'
					}/clothes/${props.state.questions.choises[9].selected}.png`}
					alt="Первый слайд"
				/>
				<img
					className="characters-bottom__mainhero"
					src={`/assets/loveinterest/${
						props.state.questions.choises[6].selected || 'female'
					}/faces/${props.state.questions.choises[7].selected}.png`}
					alt="Первый слайд"
				/>
				<img
					className="characters-bottom__mainhero"
					src={`/assets/loveinterest/${
						props.state.questions.choises[6].selected || 'female'
					}/hair/${props.state.questions.choises[8].selected}.png`}
					alt="Первый слайд"
				/>
			</div>
			<div className="marriage">
				<div className="marriage__message">
					<div className="message__field">
						{
							<>
								<p
									className={`message__text message__answer_text text${number}`}
									style={{ right: '0%' }}
								>
									{props.state.questions.choises[10].name}
								</p>
								<img
									src={`/assets/etc/answer${number}.png`}
									className={`message__image message__answer_image${number}`}
								/>
							</>
						}
					</div>
				</div>
			</div>
			<div className="question__control">
				<Link
					to={{
						pathname: `/questions/${
							parseInt(props.match.params.questionid) + 1
						}`,
					}}
					className="control__button"
					onClick={() => {
						props.getAnswer({ id: props.match.params.questionid - 1 })
						props.setBook({ id: props.match.params.questionid - 1, number })
					}}
				>
					Confirm
				</Link>
			</div>
		</>
	)
}
export default connect(
	state => ({ state }),
	dispatch => ({
		getAnswer: payload => {
			dispatch({ type: 'SET_ANSWER', payload })
		},
		setBook: payload => {
			dispatch({ type: 'SET_BOOK', payload })
		},
	}),
)(withRouter(MarriageQuestion))
