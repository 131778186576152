import { useState, useEffect } from 'react'
import Carousel from 'react-multi-carousel'
import { Carousel as RCarousel, Modal, Spinner } from 'react-bootstrap'
import { withRouter, Link } from 'react-router-dom'
import { connect } from 'react-redux'
import 'react-multi-carousel/lib/styles.css'
import './Home.scss'
import ReactTypingEffect from 'react-typing-effect'

const responsive = {
	superLargeDesktop: {
		// the naming can be any, depends on you.
		breakpoint: { max: 4000, min: 3000 },
		items: 5,
	},
	desktop: {
		breakpoint: { max: 3000, min: 1024 },
		items: 3,
	},
	tablet: {
		breakpoint: { max: 1024, min: 401 },
		items: 1.5,
	},
	mobile: {
		breakpoint: { max: 400, min: 0 },
		items: 1.3,
	},
}

function Home(props) {
	const [showed, setShowed] = useState(false)
	const [loaded, setLoaded] = useState(0)
	const [menu, setMenu] = useState(false)
	const [show, setShow] = useState(false)
	const [checkbox, setCheckbox] = useState(false)

	const handleClose = () => setShow(false)
	const handleShow = () => setShow(true)
	const [index, setIndex] = useState(0)
	const handleSelect = (selectedIndex, e) => {
		setIndex(selectedIndex)
	}
	const newGame = () => {
		props.setGame()
	}
	useEffect(() => {
		if (loaded === 5) {
			setShowed(true)
		}
	}, [loaded])
	return (
		<>
			<header className="home__header">
				<Link to="/">
					<img className="home__logo" src="/logo.png" />
				</Link>
				<div className="home-header__rightside">
					<button className="home-header__button" onClick={() => handleShow()}>
						Get started
					</button>
				</div>
			</header>
			<div className="home__slider">
				{showed ? (
					<RCarousel
						activeIndex={index}
						onSelect={handleSelect}
						interval={8000}
						className={showed ? '' : 'image-none'}
					>
						<RCarousel.Item>
							{showed ? (
								<ReactTypingEffect
									className="writing__text"
									typingDelay={0}
									cursor=" "
									eraseDelay={99999999}
									text={[
										'Do you want to be someone else? Do you want to decide yourself what to do, who to love, what to say? Make your choice! ',
									]}
									speed={50}
								/>
							) : (
								<></>
							)}
							<img
								className={
									showed ? 'd-block w-100 ' : 'd-block w-100 image-none'
								}
								src="/assets/home/slide1.png"
								alt="First slide"
							/>
							<RCarousel.Caption className="custom-caption ">
								<button
									className={
										showed
											? 'control__button pulse__button'
											: 'control__button pulse__button'
									}
									style={{ position: 'absolute', zIndex: 10, height: 48 }}
									onClick={() => handleShow()}
								>
									Customize your story
								</button>
								<img
									src="/assets/home/main.png"
									style={{ marginBottom: 20 }}
									className={showed ? 'animation' : 'animation image-none'}
								></img>
							</RCarousel.Caption>
						</RCarousel.Item>
						<RCarousel.Item>
							<img
								className={showed ? 'd-block w-100' : 'd-block w-100'}
								src="/assets/home/slide2.png"
								alt="Second slide"
							/>

							<RCarousel.Caption className="custom-caption">
								<button
									className={
										showed
											? 'control__button pulse__button'
											: 'control__button pulse__button'
									}
									style={{ position: 'absolute', zIndex: 10, height: 48 }}
									onClick={() => handleShow()}
								>
									...Bratty submissive
								</button>
								<img
									src="/assets/home/main.png"
									style={{ marginBottom: 20 }}
									className={showed ? 'animation' : 'animation image-none'}
								></img>
							</RCarousel.Caption>
						</RCarousel.Item>
						<RCarousel.Item>
							<img
								className={showed ? 'd-block w-100' : 'd-block w-100'}
								src="/assets/home/slide3.png"
								alt="Third slide"
							/>

							<RCarousel.Caption className="custom-caption">
								<button
									className={
										showed
											? 'control__button pulse__button'
											: 'control__button pulse__button'
									}
									style={{ position: 'absolute', zIndex: 10, height: 48 }}
									onClick={() => handleShow()}
								>
									...Billionaire&apos;s girlfriend
								</button>
								<img
									src="/assets/home/main.png"
									style={{ marginBottom: 20 }}
									className={showed ? 'animation' : 'animation image-none'}
								></img>
							</RCarousel.Caption>
						</RCarousel.Item>
						<RCarousel.Item>
							<img
								className={showed ? 'd-block w-100' : 'd-block w-100'}
								src="/assets/home/slide4.png"
								alt="Third slide"
							/>

							<RCarousel.Caption className="custom-caption">
								<button
									className={
										showed
											? 'control__button pulse__button'
											: 'control__button pulse__button'
									}
									style={{ position: 'absolute', zIndex: 10, height: 48 }}
									onClick={() => handleShow()}
								>
									...Dangerous lover
								</button>
								<img
									src="/assets/home/main.png"
									style={{ marginBottom: 20 }}
									className={showed ? 'animation' : 'animation image-none'}
								></img>
							</RCarousel.Caption>
						</RCarousel.Item>
						<RCarousel.Item>
							<img
								className={showed ? 'd-block w-100' : 'd-block w-100'}
								src="/assets/home/slide5.png"
								alt="Third slide"
							/>

							<RCarousel.Caption className="custom-caption">
								<button
									className={
										showed
											? 'control__button pulse__button'
											: 'control__button pulse__button'
									}
									style={{ position: 'absolute', zIndex: 10, height: 48 }}
									onClick={() => handleShow()}
								>
									...In love triangle
								</button>
								<img
									onLoad={() => setLoaded(loaded + 1)}
									src="/assets/home/main.png"
									style={{ marginBottom: 20 }}
									className={showed ? 'animation' : 'animation image-none'}
								></img>
							</RCarousel.Caption>
						</RCarousel.Item>
					</RCarousel>
				) : (
					<div
						className={
							showed ? 'spinner__background image-none' : 'spinner__background'
						}
						style={{
							width: '100%',
							height: '800px',
							display: 'flex',
							justifyContent: 'center',
							alignItems: 'center',
							zIndex: 0,
						}}
					>
						<Spinner animation="border" variant="light" />
					</div>
				)}
			</div>
			<div className="home__body">
				<div className="body__header">
					<p className="body-header__text">User Feedbacks</p>
				</div>
				<Carousel responsive={responsive} arrows={false} slidesToSlide={1}>
					<img className="slider__img" src="/assets/feedbacks/feedback1.png" />
					<img className="slider__img" src="/assets/feedbacks/feedback2.png" />
					<img className="slider__img" src="/assets/feedbacks/feedback3.png" />
					<img className="slider__img" src="/assets/feedbacks/feedback4.png" />
					<img className="slider__img" src="/assets/feedbacks/feedback5.png" />
				</Carousel>
			</div>
			<div className="home__footer">
				<div className="footer__grid">
					<div className="footer__section">
						<h3 className="section__h">Legacy</h3>
						<p className="section__p">Terms of Service</p>
						<p className="section__p">Privacy Policy</p>
					</div>
					<div className="footer__section">
						<h3 className="section__h">Help</h3>
						<p className="section__p">Contact Us</p>
						<p className="section__p">FAQ</p>
					</div>
				</div>
				<p className="footer__text">
					GM Unicorn Corporation Limited
					<br /> Florinis 7, Greg Tower, 2nd Floor, 1065, Nicosia, Cyprus{' '}
				</p>
			</div>
			<Modal
				show={show}
				onHide={handleClose}
				backdrop="static"
				style={{ top: 250 }}
				keyboard={false}
			>
				<Modal.Header
					style={{
						borderBottom: 'none',
						padding: 0,
						paddingRight: 5,
						display: 'flex',
						justifyContent: 'flex-end',
					}}
				>
					<i
						className="fas fa-times"
						style={{ fontSize: 18, margin: 5 }}
						onClick={() => handleClose()}
					></i>
				</Modal.Header>
				<Modal.Body className="modal__body">
					<div onClick={() => setCheckbox(!checkbox)}>
						<input
							type="checkbox"
							label="Check me out"
							checked={checkbox}
							style={{ height: 18, width: 18 }}
						/>
						<p className="modal__text">
							I agree to the Terms of Service and Privacy Policy
						</p>
					</div>
				</Modal.Body>
				<Modal.Footer
					style={{
						display: 'flex',
						justifyContent: 'center',
						borderTop: 'none',
						padding: 0,
						paddingBottom: 16,
					}}
				>
					<Link
						className={checkbox ? 'modal__button' : 'modal__button-disabled'}
						to="/questions/1"
						onClick={newGame}
					>
						Okay
					</Link>
				</Modal.Footer>
			</Modal>
			<div className={menu ? 'home__menu' : 'home__menu none'}>
				<div className="menu__header">
					<i
						className="fas fa-times"
						onClick={() => setMenu(false)}
						style={{ fontSize: 25 }}
					></i>
				</div>
				<div className="menu__body">
					<p className="menu-body__text">FAQ</p>
					<p className="menu-body__text">Privacy Policy</p>
					<p className="menu-body__text">Terms of service</p>
				</div>
				<hr />
				<div className="menu__footer">
					<p className="menu-footer__text">Contact us</p>
					<p className="menu-footer__text">Support@my-fantasy.life</p>
				</div>
			</div>
			<img
				onLoad={() => setLoaded(loaded + 1)}
				src="/assets/home/slide1.png"
				className="image-none abs-under"
			/>
			<img
				onLoad={() => setLoaded(loaded + 1)}
				src="/assets/home/slide2.png"
				className="image-none abs-under"
			/>
			<img
				onLoad={() => setLoaded(loaded + 1)}
				src="/assets/home/slide3.png"
				className="image-none abs-under"
			/>
			<img
				onLoad={() => setLoaded(loaded + 1)}
				src="/assets/home/slide4.png"
				className="image-none abs-under"
			/>
			<img
				onLoad={() => setLoaded(loaded + 1)}
				src="/assets/home/slide5.png"
				className="image-none abs-under"
			/>
		</>
	)
}

export default connect(
	state => ({ state }),
	dispatch => ({
		setGame: payload => {
			dispatch({ type: 'NEW_GAME' })
		},
	}),
)(withRouter(Home))
