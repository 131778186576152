import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router-dom'
import * as queryString from 'query-string'

import {
	cardHandler,
	payPalHandler,
} from './handlers/listenersHandler/listenersHandler'
import { merchantDataHandler } from './handlers/metchantDataHandler/MarchantDataHandler'
import {
	cardRequest,
	payPalRequest,
} from './handlers/requestsHandler/requestsHandler'
import './styles.scss'

const Modal = ({
	isActiveModal,
	handleCloseModal: closeModal,
	product_id,
	total,
	amount,
}) => {
	const { email, flow, bill, fbc, fbp } = useSelector(state => state.user)
	const dispatch = useDispatch()
	const history = useHistory()
	const [activePayment, setActivePayment] = useState(true)
	const inputEl = useRef(null)
	const cardRef = useRef(null)
	const { pathname, search } = useLocation()
	const data = queryString.parse(search)
	console.log('in modal  ', amount)

	const handleCloseModal = () => {
		if (pathname === '/discount' || !flow) {
			closeModal()
			const queryParams = new URLSearchParams(search)
			if (queryParams.has('modal')) {
				queryParams.delete('modal')
				history.replace({
					search: decodeURIComponent(queryParams.toString()),
				})
			}
			return
		}
		if (pathname === '/sale' && flow) {
			return history.push(`/discount?email=${email}`)
		}
	}
	useEffect(() => {
		payPalRequest({
			email: email || data.email,
			amount: total,
			product_id,
			pixel_id: process.env.REACT_APP_PIXEL_ID,
			external_id: window.id,
			userAgent: navigator.userAgent,
			user_source: window.location.origin,
			fbc,
			fbp,
		}).then(res => {
			const oldScript = document.querySelector('#paypal-request')
			if (oldScript) {
				oldScript.remove()
			}
			inputEl?.current?.removeEventListener(
				'order-processed', 
				payPalHandler(history, dispatch, amount, pathname, bill, () => {
					window.fbq('track', 'Purchase', { currency: 'USD', value: total })
				})
			)
			const script = document.createElement('script')
			script.src = res.url
			script.id = 'paypal-request'
			inputEl?.current?.addEventListener(
				'order-processed',
				payPalHandler(history, dispatch, amount, pathname, bill, () => {
					window.fbq('track', 'Purchase', { currency: 'USD', value: total })
				}),
			)
			document.body.appendChild(script)
		})
		cardRequest({
			email: email || data.email,
			amount: total,
			product_id,
			pixel_id: process.env.REACT_APP_PIXEL_ID,
			external_id: window.id,
			userAgent: navigator.userAgent,
			user_source: window.location.origin,
			fbc,
			fbp,
		}).then(res => {
			window.removeEventListener(
				'message', 
				cardHandler(history, dispatch, amount, pathname, bill, () => {
					window.fbq('track', 'Purchase', {currency: 'USD', value: total})
				}),
			)

			const data = merchantDataHandler(res, flow)
			window.addEventListener(
				'message',
				cardHandler(history, dispatch, amount, pathname, bill, () => {
					window.fbq('track', 'Purchase', { currency: 'USD', value: total })
				}),
			)
			if (cardRef.current !== null) {
				cardRef.current.innerHTML = ''
			}
			window.PaymentFormSdk.init(data)
		})
	}, [product_id])

	useEffect(() => {
		if (isActiveModal) {
			document.body.style.overflow = 'hidden'
			return
		}
		document.body.style.overflow = 'visible'
	}, [isActiveModal])

	return (
		<div
			className={`sale-screen-backdrop ${
				isActiveModal ? 'show-modal' : 'hide-modal'
			}`}
		>
			<div className="sale-screen-modal">
				<div className="sale-screen-modal__header">
					<div className="modal__btn">
						<div className="modal__btn-line"></div>
						<img
							src="/img/sale/close-modal.png"
							alt="X"
							className="modal__btn-close"
							onClick={handleCloseModal}
						/>
					</div>
					<div className="modal__cards">
						<h1 className="modal__title">Select a secure payment method</h1>
						<div
							className={`modal__card card__paypal ${
								activePayment && 'active-paymant'
							}`}
							onClick={() => {
								setActivePayment(true)
							}}
						>
							<div>
								<span>PayPal</span>
								<img
									src={`/img/sale/paypal-${
										!activePayment ? 'de' : ''
									}active.png`}
									alt="credits"
									className="paypal-image"
								></img>
							</div>
							<div className="modal__card-done">
								<img src="/img/sale/done-icon.png" alt="credits"></img>
							</div>
						</div>
						<div
							className={`modal__card card__credit ${
								!activePayment && 'active-paymant'
							}`}
							onClick={() => {
								setActivePayment(false)
							}}
						>
							<div>
								<span>Credit cards</span>
								<img
									src={`/img/sale/credits-${
										activePayment ? 'de' : ''
									}active.png`}
									alt="credits"
									className="credits-image"
								></img>
							</div>
							<div className="modal__card-done">
								<img src="/img/sale/done-icon.png" alt="credits"></img>
							</div>
						</div>
					</div>
				</div>

				<div className="sale-screen-modal__content">
					{/* <div className="modal__annual-plan">
						<span>Annual plan:</span>
						<span>${annualPlan}</span>
					</div>
					<div className="modal__discount">
						<span>Discount:</span>
						<span>-${discount}</span>
					</div> */}
					<div className="modal__total">
						<span>Total:</span>
						<span>${total}</span>
					</div>

					<div
						style={{
							marginTop: 'auto',
						}}
					>
						<div
							className={
								activePayment
									? 'modal__button '
									: 'modal__button show-payment-type'
							}
							ref={inputEl}
							id="paypal-button"
							style={{
								border: 'none',
								width: '100%',
								background: 'white',
								marginTop: '80px',
							}}
						></div>
					</div>

					<div
						id="solid-payment-form-container"
						className={activePayment ? 'show-payment-type' : ''}
						ref={cardRef}
					></div>

					<div className="modal__privacy">
						{activePayment && 'The safer, easier way to pay'}
					</div>
				</div>
			</div>
		</div>
	)
}

export { Modal }
