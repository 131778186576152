import React from 'react'
import { Button } from '../../../../components/common/button'
import Input from '../../../../components/common/Input'

import './styles.scss'

const AuthorizeForm = ({
	isValidEmail,
	formValue,
	setFormValue,
	handleKeyboardSubmitEmail,
	onSubmitEmail,
}) => (
	<div className="authorize-form">
		<p className="authorize-form__description">
			Enter Your email which you
			<br />
			used on this website
		</p>
		<Input
			maxWidth={335}
			isValid={isValidEmail}
			formValue={formValue}
			setFormValue={setFormValue}
			placeholder="email@gmail.com"
			handleKeyboardSubmitEmail={handleKeyboardSubmitEmail}
		/>
		<div className="authorize-form-button-container">
			<Button
				width={335}
				label="Continue"
				onClick={onSubmitEmail}
				disabled={!isValidEmail}
			/>
		</div>

		<a
			href="https://mail.google.com/mail/u/0/?fs=1&to=support@my-fanstasyapp.com&tf=cm&su=Forgot email"
			className="authorize-form__link"
			target="_blank"
			rel="noreferrer"
		>
			Have you forgotten email?
		</a>
	</div>
)

export default AuthorizeForm
