import { withRouter } from 'react-router'
import { useState, useEffect } from 'react'
import './Header.scss'
import { ProgressBar } from 'react-bootstrap'
import { connect } from 'react-redux'

function Header(props) {
	// const [modal, setModal] = useState(false)
	const [percentage, setPercentage] = useState(0)
	useEffect(() => {
		setPercentage(
			(props.match.params.questionid / props.state.questions.choises.length) *
				100,
		)
	})
	// const modalHandler = () => {
	// 	setModal(!modal)
	// }
	return (
		<>
			<header className="header">
				{/* <i className="fas fa-bars header__icon" onClick={modalHandler}></i> */}
			</header>
			<ProgressBar variant="custom" className="progressbar" now={percentage} />

			{/* <div className={modal ? 'modalwindow' : 'modalwindow none'}>
				<div className="modal__header">
					<i
						className="far fa-times-circle modal__headericon"
						onClick={modalHandler}
					></i>
				</div>
			</div> */}
		</>
	)
}
export default connect(state => ({ state }))(withRouter(Header))
