import React, { useState } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { TaskBox, Header } from '../exporter'
import './BackgroundSelector.scss'
import { Link } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'

function BackgroundSelector(props) {
	const [counter, setCounter] = useState(0)
	const [loaded, setLoaded] = useState(
		props.state.questions.choises[props.match.params.questionid - 1]
			.sliderTemplates.length - 1,
	)
	const [visible, setVisible] = useState(false)
	const [disabled, setDisabled] = useState(false)
	const answer = () => {
		props.getAnswer({ id: props.match.params.questionid - 1 })
		setVisible(false)
	}
	const increment = () => {
		setLoaded(
			props.state.questions.choises[props.match.params.questionid - 1]
				.sliderTemplates.length - 1,
		)
		setCounter(counter + 1)
		if (counter === loaded && counter !== 0) {
			setVisible(true)
		}
	}
	const setBackground = bool => {
		setDisabled(true)
		setTimeout(() => {
			setDisabled(false)
		}, 600)
		if (bool) {
			props.nextSelect({ id: props.match.params.questionid - 1 })
		} else {
			props.prevSelect({ id: props.match.params.questionid - 1 })
		}
	}
	return (
		<div className="w-100 questioncomponent">
			<div
				id="carouselExampleControls"
				className="carousel slide background-slider"
				data-ride="carousel"
			>
				<div className="carousel-inner">
					{props.state.questions.choises[
						props.match.params.questionid - 1
					].sliderTemplates.map((elem, index) => {
						return (
							<div
								className={
									index === 0 ? 'carousel-item active' : 'carousel-item'
								}
								key={index}
							>
								<Spinner animation="border" className="preloader-background" />
								<img
									className={
										visible
											? 'd-block w-100 background-block-image'
											: 'd-block w-100 background-block-image image-none'
									}
									onLoad={() => increment()}
									src={`/assets/backgrounds/${elem}.png`}
									alt="Third slide"
								/>
							</div>
						)
					})}
				</div>
			</div>
			<div
				className="characters-bottom"
				style={{ marginBottom: -80, height: '100%' }}
			>
				<img
					className={
						visible
							? 'characters-bottom__mainhero'
							: 'characters-bottom__mainhero image-none'
					}
					src={`/assets/mainhero/${
						props.state.questions.choises[0].selected || 'female'
					}/hair/${props.state.questions.choises[2].selected}back.png`}
					alt="Первый слайд"
				/>
				<img
					className={
						visible
							? 'characters-bottom__mainhero'
							: 'characters-bottom__mainhero image-none'
					}
					src={`/assets/mainhero/${
						props.state.questions.choises[0].selected || 'female'
					}/body/${props.state.questions.choises[1].selected}.png`}
					alt="Первый слайд"
				/>
				<img
					className={
						visible
							? 'characters-bottom__mainhero'
							: 'characters-bottom__mainhero image-none'
					}
					src={`/assets/mainhero/${
						props.state.questions.choises[0].selected || 'female'
					}/clothes/${props.state.questions.choises[3].selected}.png`}
					alt="Первый слайд"
				/>
				<img
					className={
						visible
							? 'characters-bottom__mainhero'
							: 'characters-bottom__mainhero image-none'
					}
					src={`/assets/mainhero/${
						props.state.questions.choises[0].selected || 'female'
					}/faces/${props.state.questions.choises[1].selected}.png`}
					alt="Первый слайд"
				/>
				<img
					className={
						visible
							? 'characters-bottom__mainhero'
							: 'characters-bottom__mainhero image-none'
					}
					src={`/assets/mainhero/${
						props.state.questions.choises[0].selected || 'female'
					}/hair/${props.state.questions.choises[2].selected}.png`}
					alt="Первый слайд"
				/>
				<img
					className={
						visible
							? 'characters-bottom__loveinterest'
							: 'characters-bottom__loveinterest image-none'
					}
					src={`/assets/loveinterest/${
						props.state.questions.choises[6].selected || 'female'
					}/hair/${props.state.questions.choises[8].selected}back.png`}
					alt="Первый слайд"
				/>
				<img
					className={
						visible
							? 'characters-bottom__loveinterest'
							: 'characters-bottom__loveinterest image-none'
					}
					src={`/assets/loveinterest/${
						props.state.questions.choises[6].selected || 'female'
					}/body/${props.state.questions.choises[7].selected}.png`}
					alt="Первый слайд"
				/>
				<img
					className={
						visible
							? 'characters-bottom__loveinterest'
							: 'characters-bottom__loveinterest image-none'
					}
					src={`/assets/loveinterest/${
						props.state.questions.choises[6].selected || 'female'
					}/clothes/${props.state.questions.choises[9].selected}.png`}
					alt="Первый слайд"
				/>
				<img
					className={
						visible
							? 'characters-bottom__loveinterest'
							: 'characters-bottom__loveinterest image-none'
					}
					src={`/assets/loveinterest/${
						props.state.questions.choises[6].selected || 'female'
					}/faces/${props.state.questions.choises[7].selected}.png`}
					alt="Первый слайд"
				/>
				<img
					className={
						visible
							? 'characters-bottom__loveinterest'
							: 'characters-bottom__loveinterest image-none'
					}
					src={`/assets/loveinterest/${
						props.state.questions.choises[6].selected || 'female'
					}/hair/${props.state.questions.choises[8].selected}.png`}
					alt="Первый слайд"
				/>
			</div>
			<Header />
			<TaskBox />
			<a
				className="carousel-control-prev"
				href="#carouselExampleControls"
				role="button"
				data-slide="prev"
				onClick={() => setBackground(0)}
			>
				<img
					className={
						disabled
							? 'background-changer__arrow arrow pointer-disabled'
							: 'background-changer__arrow arrow'
					}
					src="/assets/arrows/arrowLeft.png"
				/>
				<span className="sr-only">Previous</span>
			</a>
			<a
				className="carousel-control-next"
				href="#carouselExampleControls"
				role="button"
				data-slide="next"
				onClick={() => setBackground(1)}
			>
				<img
					className={
						disabled
							? 'background-changer__arrow arrow pointer-disabled'
							: 'background-changer__arrow arrow'
					}
					src="/assets/arrows/arrowRight.png"
				/>
				<span className="sr-only">Next</span>
			</a>
			<div className="question__control">
				<Link
					to={{
						pathname: `/questions/${
							parseInt(props.match.params.questionid) + 1
						}`,
					}}
					className="control__button"
					onClick={() => answer()}
				>
					Confirm
				</Link>
			</div>
		</div>
	)
}

export default connect(
	state => ({ state }),
	dispatch => ({
		setSelect: payload => {
			dispatch({ type: 'SET_SELECT', payload })
		},
		nextSelect: payload => {
			dispatch({ type: 'NEXT_SELECT', payload })
		},
		prevSelect: payload => {
			dispatch({ type: 'PREV_SELECT', payload })
		},
		getAnswer: payload => {
			dispatch({ type: 'SET_ANSWER', payload })
		},
	}),
)(withRouter(BackgroundSelector))
