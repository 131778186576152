import React from 'react'

import './styles.scss'

const Input = ({
	maxWidth,
	isValid,
	formValue,
	setFormValue,
	handleKeyboardSubmitEmail,
	placeholder,
}) => {
	return (
		<input
			type="email"
			id="email"
			className={`custom-input ${isValid ? 'custom-input--valid' : ''}`}
			value={formValue}
			onChange={e => setFormValue(e.target.value)}
			onKeyDown={e => handleKeyboardSubmitEmail(e)}
			placeholder={placeholder}
			style={{
				maxWidth,
			}}
		/>
	)
}

export default Input
