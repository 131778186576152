import React, { useEffect, useState } from 'react'
import { Link, useLocation } from 'react-router-dom'
import { ampEvent } from '../../../../../utils/amplitude'

import { ReactComponent as ArrowIcon } from './images/burger-option-arrow.svg'

import './styles.scss'

const BurgerMenu = ({ isOpen, handleCloseBurgerMenu }) => {
	const [isOpenTerms, setIsOpenTerms] = useState(false)

	const toggleTerms = () => setIsOpenTerms(!isOpenTerms)

	const { pathname, search } = useLocation()

	useEffect(() => {
		setIsOpenTerms(false)
	}, [isOpen])

	return (
		<div
			className={`burger-menu-container ${
				isOpen ? 'burger-menu-container--open' : ''
			}`}
		>
			<div className="burger-menu">
				<div className="burger-menu-options">
					<div className="burger-menu__option">
						<Link
							onClick={() => {
								ampEvent('authorization_email')
								handleCloseBurgerMenu()
							}}
							to={{
								pathname: '/account',
								state: { from: pathname + search },
							}}
						>
							My Account
						</Link>
					</div>
					<div className="burger-menu__option">
						<Link
							onClick={handleCloseBurgerMenu}
							to={{
								pathname: '/faq',
								state: { from: pathname + search },
							}}
						>
							FAQ
						</Link>
					</div>
					<div
						className={`burger-menu__option ${
							isOpenTerms ? 'burger-menu__option--open' : ''
						}`}
						onClick={toggleTerms}
					>
						<span>Terms & Policies</span>
						<ArrowIcon />
					</div>
				</div>
				<div
					className={`terms-and-policies ${
						isOpenTerms ? 'terms-and-policies--open' : ''
					}`}
				>
					<div className="terms-and-policies__option">
						<Link onClick={handleCloseBurgerMenu} to="/money-back">
							Money-Back Policy
						</Link>
					</div>
					<div className="terms-and-policies__option">
						<Link onClick={handleCloseBurgerMenu} to="/privacy-policy">
							Privacy policy
						</Link>
					</div>
					<div className="terms-and-policies__option">
						<Link onClick={handleCloseBurgerMenu} to="/terms-of-use">
							Terms and Conditions of Use
						</Link>
					</div>
					<div className="terms-and-policies__option">
						<Link onClick={handleCloseBurgerMenu} to="/subscription-terms">
							Subscription Terms
						</Link>
					</div>
				</div>
				<div className="burger-menu-contacts">
					<img
						src="/img/common/burgerMenu/email-icon.svg"
						alt="email-icon"
						className="burger-menu-contacts__email-icon"
					/>
					<div className="contacts-info">
						<p className="contacts-info__label">Contact Us</p>
						<a
							href="https://mail.google.com/mail/?view=cm&fs=1&to=support@my-fantasyapp.com"
							target="_blank"
							rel="noreferrer"
							className="contacts-info__link"
						>
							support@my-fantasyapp.com
						</a>
					</div>
				</div>
			</div>
		</div>
	)
}

export default BurgerMenu
