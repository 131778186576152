import React from 'react'

import './styles.scss'

const WarningMessage = () => {
	return (
		<div className="warning-message">
			<img
				src="/img/common/burgerMenu/radiobutton.png"
				alt="Warning sign."
				className="warning-message__image"
			/>
			<p className="warning-message__description">
				We can&apos;t find an account with this email address
			</p>
		</div>
	)
}

export default WarningMessage
