import React from 'react'
import { Button } from '../../common'

import './styles.scss'

const Price = ({
	selectedOffer,
	offers,
	handleSelectOffer,
	flow,
	handleOpenModal,
}) => {
	return flow ? (
		<div className="sale-price">
			<img
				src="/img/sale/vip-pass.png"
				alt="vip-pass"
				className="sale-price__image"
			/>
			<div className="price-info">
				<h3 className="price-info__title" id="price">
					Total due today: <span>$0.00</span>
				</h3>
				<span className="price-info__subtitle">
					Included in your membership:
				</span>
				<div className="price-offers">
					<div className="lock-icon-wrapper">
						<img
							src="/img/sale/unlocked.png"
							alt="lock"
							className="lock-icon"
						/>
					</div>
					<span className="price-offer">
						You get customized stories to adjust to preferences and access to
						<b> 1500+ stories</b>
					</span>
					<div className="lock-icon-wrapper">
						<img
							src="/img/sale/unlocked.png"
							alt="lock"
							className="lock-icon"
						/>
					</div>
					<span className="price-offer">
						You access the darkroom to find the sexually brightest stories
					</span>
					<div className="lock-icon-wrapper">
						<img
							src="/img/sale/unlocked.png"
							alt="lock"
							className="lock-icon"
						/>
					</div>
					<span className="price-offer">
						Only you decide if you wanna read limitlessly about revenge or
						friendship, real-life or fantasy
					</span>
				</div>
				<Button label="Try for free" onClick={handleOpenModal} />
				<p className="price-info__bottom-description">
					No charges for 3 days
					<br />
					Cancel anytime · $9.99 billed weekly
				</p>
			</div>
		</div>
	) : (
		<div className="offer">
			<h2 className="offer__title">
				Start Your
				<br />3 days Trial for $0.99
			</h2>
			<img className="offer__image" src="/img/sale/offer-banner.png" />
			<div className="offer-options" id="price">
				{offers.map(offer => (
					<div
						className={`option-wrapper  ${
							offer.isSelected ? 'option-wrapper--selected' : ''
						}`}
						key={offer.id}
						onClick={() => handleSelectOffer(offer.id)}
					>
						<div className="option">
							{offer.isBestler && (
								<div className="option__bestler-mark">
									<span>Bestller</span>
								</div>
							)}
							<div className="option-info">
								<h5 className="option-info__trail">{offer.trial}</h5>
								<p className="option-info__price">{offer.pricePerWeek}</p>
							</div>
							<img
								src={offer.priceImage}
								alt={`price${offer.id}`}
								className="option__price-image"
							/>
						</div>
					</div>
				))}
			</div>
			<Button width={335} label="Try Now" onClick={handleOpenModal} />

			<p className="offer__option-description">
				Unless you cancel <span className="offer__option-description-bold" >at least 24 hours</span> before the end of the <span className="offer__option-description-bold" >3-day{' '}
				{flow && 'free'}
				trial</span>, you will be automatically charged <span className="offer__option-description-bold" >U.S.
				{selectedOffer.pricePerWeek}</span> subscription. Subscriptions <span className="offer__option-description-bold" >renew
				automatically</span> at the end of each period unless you cancel at least 24
				hours before the end of then-current period. To avoid being charged,
				cancel the subscription in your account settings. If you are unsure how
				to cancel, visit our{' '}
				<a href="/subscription-terms">Subscription Terms.</a>
			</p>
		</div>
	)
}

export { Price }
