import React, { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useHistory, useLocation } from 'react-router'
import { scroller } from 'react-scroll'
import { Button } from '../../components/common'

import {
	Fantasy,
	Footer,
	Header,
	Modal,
	Price,
	Reviews,
} from '../../components/sale'
import { ampEvent } from '../../utils/amplitude'
import { QueryHandler, SendConfirmEmail } from '../../utils/queryHandlers'
import { offersList } from './data'
import { setAnnualPrice } from '../../redux/actions/user'

import './styles.scss'

const SaleScreen = () => {
	const history = useHistory()
	const { search } = useLocation()
	const dispatch = useDispatch()
	const { email, isSendedConfirmEmail, flow } = useSelector(
		state => state.user,
	)
	const [isActiveModal, setIsActiveModal] = useState(false)
	const [offers, setOffers] = useState(offersList)
	let selectedOffer = offers.find(offer => offer.isSelected)

	const queryParams = new URLSearchParams(search)

	const handleSelectOffer = id =>
		setOffers(
			offers.map(offer => {
				if (offer.id === id) {
					offer.isSelected = true
					selectedOffer = offer
					console.log(selectedOffer.price)
					return offer
				}

				offer.isSelected = false
				return offer
			}),
		)

	const handleOpenModal = () => {
		setIsActiveModal(true)
		ampEvent('Checkout_shown')
	}

	const handleCloseModal = () => setIsActiveModal(false)

	const handleScrollToPrice = () => {
		scroller.scrollTo('price', {
			duration: 1500,
			spy: true,
			exact: true,
			smooth: true,
			offset: -180,
		})
	}

	useEffect(() => {
		QueryHandler(search, {
			setIsActiveModal,
			dispatch,
			history,
		})
		if (!isSendedConfirmEmail && email) {
			const body = {
				email,
				type_id: 1,
				project_id: process.env.REACT_APP_PROJECT_ID,
				button_url: {
					front_url: window.location.origin,
					pathname: location.pathname,
					queryParams: [
						{
							key: 'email',
							value: email,
						},
						{
							key: 'message',
							value: true,
						},
					],
				},
			}
			if (flow) {
				body.button_url.queryParams.push({
					key: 'flow',
					value: flow,
				})
			}
			SendConfirmEmail(body, dispatch)
			if (flow) {
				history.push(`/sale?flow=${flow}&email=${email}&message=true`)
			} else {
				history.push(`/sale?email=${email}&message=true`)
			}
		}
	}, [email, isSendedConfirmEmail])

	useEffect(() => {
		setTimeout(() => {
			ampEvent('sales_screen_shown')
			if (queryParams.has('modal')) {
				ampEvent('Checkout_shown')
			}
		}, 0)
	}, [])

	useEffect(
		() => dispatch(setAnnualPrice({annualPrice: selectedOffer?.price})),
		[selectedOffer],
	)

	return (
		<div className="sale">
			<Header handleScrollToPrice={handleScrollToPrice} />
			{!flow ? (
				<img
					src="/img/sale/come-in-icon.png"
					alt="come-in-icon"
					className="sale__welcome"
				/>
			) : (
				<img
					src="/img/sale/come-in-icon11.png"
					alt="come-in-icon11"
					className="sale__welcome"
				/>
			)}
			<Fantasy onBtnClick={handleScrollToPrice} flow={flow} />
			<div className="dark-room">
				<img
					src="/img/sale/dark-room.png"
					alt="dark-room"
					className="dark-room__image"
				/>
				<div className="button-wrapper">
					<Button
						label={!flow ? 'Try Now' : 'Try for Free'}
						onClick={handleScrollToPrice}
					/>
				</div>
			</div>
			<div className="spicy-story">
				{flow && (
					<>
						<img
							src="/img/sale/diamond.png"
							alt="diamond"
							className="spicy-story__image"
						/>
						<h2 className="spicy-story__title">Ready to be even hotter?</h2>
						<p className="spicy-story__description">
							Feel spiciest stories ever
						</p>
					</>
				)}
			</div>
			<Price
				selectedOffer={selectedOffer}
				offers={offers}
				handleSelectOffer={handleSelectOffer}
				flow={flow}
				handleOpenModal={handleOpenModal}
			/>
			<Reviews />
			<Footer />
			<Modal
				total={flow ? 0 : 0.99}
				amount={!flow ? selectedOffer.price : 0}
				handleCloseModal={handleCloseModal}
				isActiveModal={isActiveModal}
				email={email}
				product_id={
					!flow ? selectedOffer.product_id : process.env.REACT_APP_PRODUCT_ID
				}
			/>
		</div>
	)
}

export { SaleScreen }
