import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router'
import lottie from 'lottie-web'

import './styles.scss'

const ProcessingScreen = () => {
	const container = useRef(null)

	const history = useHistory()

	const [isActive, setIsActive] = useState(true)
	const [progress, setProgress] = useState(0)
	const [loadingLabel, setLoadingLabel] = useState('Calculating the answers...')

	useEffect(() => {
		if (progress <= 99) {
			if (progress === 50) {
				setLoadingLabel('Picking up the perfect match...')
			}
			if (progress === 75) {
				setLoadingLabel('Looking through 100+ stories...')
			}
			if (progress === 99) {
				setLoadingLabel('Are you ready to find out the result?')
			}

			setTimeout(() => {
				setProgress(progress + 1)
			}, 60)
			return
		}

		setTimeout(() => setIsActive(false), 1500)
		setTimeout(() => history.push('/email'), 1850)
	}, [progress])

	useEffect(() => {
		document.body.style.overflow = 'hidden'

		return () => {
			document.body.style.overflow = 'visible'
		}
	})

	useEffect(() => {
		lottie.loadAnimation({
			container: container.current,
			renderer: 'svg',
			loop: false,
			autoplay: true,
			animationData: require('./heart-loading.json'),
		})
	}, [])

	return (
		<>
			<div className="processing-container">
				<div
					className={`processing ${
						isActive ? 'processing--show' : 'processing--hide'
					}`}
				>
					<div className="processing__loading" ref={container}></div>
					<div className="processing-info">
						<p className="processing-info__progress">{progress}%</p>
						<p className="processing-info__label">{loadingLabel}</p>
					</div>
				</div>
			</div>
			<img src="/img/email/bg.png" className="none-media" />
			<img src="/img/email/logo.png" className="none-media" />
		</>
	)
}

export { ProcessingScreen }
