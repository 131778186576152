const getLastBackground = (scenes, currentSceneId) => {
	const croppedScenes = scenes.slice(0, currentSceneId)
	const backgroundScenes = croppedScenes.filter(
		scene => scene.type === 'background',
	)

	return backgroundScenes.length
		? backgroundScenes[backgroundScenes.length - 1].backgroundName
		: ''
}

export default getLastBackground
