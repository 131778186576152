export const SET_EMAIL = 'SET_EMAIL'
export const SET_CARD_TOKEN = 'SET_CARD_TOKEN'
export const SET_PAYPAL_TOKEN = 'SET_PAYPAL_TOKEN'
export const SET_BILL = 'SET_BILL'
export const SET_ERROR = 'SET_ERROR'
export const SEND_CONFIRM_EMAIL = 'SEND_CONFIRM_EMAIL'
export const SET_IS_TRIGGERED_EVENT_PURCHASE = 'SET_IS_TRIGGERED_EVENT_PURCHASE'
export const SET_FLOW = 'SET_FLOW'
export const SET_FBC = 'SET_FBC'
export const SET_FBP = 'SET_FBP'
export const SET_ANNUAL_PRICE = 'SET_ANNUAL_PRICE'

export const setEmail = payload => ({ type: SET_EMAIL, payload })

export const setCardToken = payload => ({ type: SET_CARD_TOKEN, payload })

export const setPaypalToken = payload => ({ type: SET_PAYPAL_TOKEN, payload })

export const setBill = payload => ({ type: SET_BILL, payload })

export const setError = payload => ({ type: SET_ERROR, payload })

export const setFbp = payload => ({ type: SET_FBP, payload })

export const setFbc = payload => ({ type: SET_FBC, payload })

export const sendConfirmEmail = () => ({ type: SEND_CONFIRM_EMAIL })

export const setIstriggeredEventPurchase = () => ({
	type: SET_IS_TRIGGERED_EVENT_PURCHASE,
})

export const setFlow = payload => ({ type: SET_FLOW, payload })

export const setAnnualPrice = payload => ({ type: SET_ANNUAL_PRICE, payload })
