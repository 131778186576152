import {
	ampEventWithEventProperty,
	ampSetUserProperty,
} from '../../../utils/amplitude'
import { SendSuccessEmail } from '../../../utils/queryHandlers'
import {
	SEND_CONFIRM_EMAIL,
	SET_BILL,
	SET_CARD_TOKEN,
	SET_EMAIL,
	SET_ERROR,
	SET_FLOW,
	SET_PAYPAL_TOKEN,
	SET_FBC,
	SET_FBP,
	SET_ANNUAL_PRICE,
} from '../../actions/user'

export const STATE_KEY = 'user'

const initialState = {
	flow: '',
	email: '',
	cardToken: '',
	paypalToken: '',
	bill: false,
	isSendedConfirmEmail: false,
	isSendedSuccessEmail: false,
	isTriggeredEventPurchase: false,
	error: '',
	fbp: '',
	fbc: '',
	annualPrice: 0,
}

export const userReducer = (state = initialState, action) => {
	const { payload } = action

	switch (action.type) {
		case SET_EMAIL: {
			const { email } = payload
			return { ...state, email }
		}
		case SET_CARD_TOKEN: {
			const { cardToken } = payload
			return { ...state, cardToken }
		}
		case SET_PAYPAL_TOKEN: {
			const { payPalToken } = payload
			return { ...state, payPalToken }
		}
		case SET_BILL: {
			const { type, total } = payload
			console.log('in set bill  ', total)
			if (!state.bill) {
				ampSetUserProperty('payment_type', type)
				if (total === 0 || total) {
					ampEventWithEventProperty('purchase', { value: state.flow === '' ? state.annualPrice : 0})
				}

				SendSuccessEmail(state.email)
			}
			return { ...state, bill: true, isSendedSuccessEmail: true }
		}
		case SET_ERROR: {
			const { error } = payload
			return { ...state, error }
		}
		case SEND_CONFIRM_EMAIL: {
			return { ...state, isSendedConfirmEmail: true }
		}
		case SET_FLOW: {
			const { flow } = payload
			return { ...state, flow }
		}
		case SET_FBP: {
			const { fbc } = payload
			return { ...state, fbc }
		}
		case SET_FBC: {
			const { fbp } = payload
			return { ...state, fbp }
		}

		case SET_ANNUAL_PRICE: {
			const { annualPrice } = payload
			return { ...state, annualPrice }
		}

		default:
			return state
	}
}
