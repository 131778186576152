import React from 'react'

import './styles.scss'

const Header = ({
	isStory,
	isTransparent,
	isShowBackButton = false,
	isOpenBurgerMenu,
	toggleBurgerMenu,
	onClickBackHandler,
}) => (
	<div
		className={`header-container ${
			isOpenBurgerMenu ? 'header-container--opened-burger' : ''
		}`}
		style={{
			background: isTransparent
				? 'linear-gradient(181.93deg, #000000 -19.21%, rgba(0, 0, 0, 0) 98.37%)'
				: '',
		}}
	>
		<div className="header">
			<div
				className="back-button"
				onClick={() => isShowBackButton && onClickBackHandler()}
			>
				{isShowBackButton ? (
					<>
						<img
							src="/img/common/header/left-arrow.svg"
							alt="left-arrow"
							className="back-button__arrow"
						/>
						<p className="back-button__text">Back</p>
					</>
				) : null}
			</div>
			{!isStory && (
				<img
					src="/img/common/header/logo.png"
					alt="logo"
					className="header-logo"
				/>
			)}

			<div
				className={`burger-icon ${
					isStory && !isOpenBurgerMenu ? 'burger-icon--pink' : ''
				}`}
				onClick={toggleBurgerMenu}
			>
				<img
					src={`/img/common/burgerMenu/${
						isOpenBurgerMenu ? 'close-' : ''
					}burger-icon.svg`}
					alt="menu-icon"
					className="burger-icon__image"
				/>
			</div>
		</div>
	</div>
)

export default Header
