import React from 'react'

import './styles.scss'

const Button = ({ label, onClick, disabled = false, width: maxWidth }) => {
	return (
		<button
			style={{ maxWidth }}
			className={`button ${disabled ? 'button--disabled' : ''}`}
			onClick={onClick}
			disabled={disabled}
		>
			{label}
		</button>
	)
}

export { Button }
