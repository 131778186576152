import React from 'react'
import { useLocation } from 'react-router'
import { Button } from '../../common'

import './styles.scss'

const Fantasy = ({ onBtnClick, flow }) => {
	const { pathname } = useLocation()

	if (pathname === '/discount') {
		return (
			<div className="sale-fantasy" style={{ marginBottom: 55 }}>
				<img
					src="/img/discount/rating.png"
					alt="lock"
					className="sale-fantasy__rating"
				/>
				<h2 className="discount-fantasy__title">
					A plenty of great stories approved by our users wait for you!
				</h2>
				<div className="video-wrapper">
					<video
						autoPlay="autoplay"
						loop
						muted
						src="/video/sale/fantasy.mp4"
						type="video/mp4"
						className="video-wrapper__video"
						playsInline
					/>
					<img
						src="/img/sale/phone-frame.png"
						alt="phone-frame"
						className="video-wrapper__frame"
					/>
					<div className="button-wrapper">
						<Button label="Try for Free" onClick={onBtnClick} />
					</div>
				</div>
			</div>
		)
	}
	if (pathname === '/sale') {
		return (
			<div className="sale-fantasy">
				<img
					src="/img/sale/lock.png"
					alt="lock"
					className="sale-fantasy__lock"
				/>
				{flow ? (
					<>
						<h2 className="sale-fantasy__title">
							Discover new episodes full of passion
						</h2>
						<p className="sale-fantasy__subtitle">
							Be ready to set free your imagination and liberate your hidden
							desires
						</p>
					</>
				) : (
					<>
						<h2 className="sale-fantasy__title">
							Awaken fantasy in game determined by your choice
						</h2>
						<p className="sale-fantasy__subtitle">
							Love episodes will lead you through your life. Explore what is
							hidden in your imagination, and read customized stories based on
							your preferences.
						</p>
					</>
				)}
				<div className="video-wrapper">
					<video
						autoPlay="autoplay"
						loop
						muted
						src="/video/sale/fantasy.mp4"
						type="video/mp4"
						className="video-wrapper__video"
						playsInline
					/>
					<img
						src="/img/sale/phone-frame.png"
						alt="phone-frame"
						className="video-wrapper__frame"
					/>
					<div className="button-wrapper">
						<Button
							label={flow ? 'Try for Free' : 'Try Now'}
							onClick={onBtnClick}
						/>
					</div>
				</div>
			</div>
		)
	}
}

export { Fantasy }
