import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { TaskBox, Header, BottomPersons } from '../exporter'
import './BackgroundAndTitleSelector.scss'
import { Link } from 'react-router-dom'
import { Spinner } from 'react-bootstrap'

function BackgroundSelector(props) {
	const [nextstep, setNextstep] = useState(false)
	const [loaded, setLoaded] = useState(
		props.state.questions.choises[props.match.params.questionid - 1]
			.sliderTemplates.length - 1,
	)
	const [visible, setVisible] = useState(false)
	const [counter, setCounter] = useState(0)
	const [reload, setReload] = useState(false)
	const [disabled, setDisabled] = useState(false)
	const [characters, setCharacters] = useState(false)
	useEffect(() => {
		setCharacters(
			props.state.questions.choises[props.match.params.questionid - 1]
				.characters,
		)
	})
	useEffect(() => {}, [visible])
	const increment = () => {
		setLoaded(
			props.state.questions.choises[props.match.params.questionid - 1]
				.sliderTemplates.length - 1,
		)
		setCounter(counter + 1)
		if (counter === loaded && counter !== 0) {
			setVisible(true)
		}
	}
	useEffect(() => {
		props.nextSelect({ id: props.match.params.questionid - 1 })
	}, [nextstep])
	const setBackground = bool => {
		setDisabled(true)
		setTimeout(() => {
			setDisabled(false)
		}, 600)
		setReload(!reload)
		if (bool) {
			props.nextSelect({ id: props.match.params.questionid - 1 })
		} else {
			props.prevSelect({ id: props.match.params.questionid - 1 })
		}
	}
	const answer = () => {
		props.getAnswer({ id: props.match.params.questionid - 1 })
		document.getElementsByClassName('active')[0].classList.remove('active')
		document.getElementsByClassName('carousel-item')[0].classList.add('active')
		setNextstep(!nextstep)
		setCounter(0)
		setVisible(false)
	}
	return (
		<div className="w-100 questioncomponent">
			<div
				id="carouselExampleControls"
				className="carousel slide background-slider"
				data-ride="carousel"
				data-interval="false"
			>
				<div className="carousel-inner">
					{props.state.questions.choises[
						props.match.params.questionid - 1
					].sliderTemplates.map((elem, index) => {
						return (
							<div
								className={
									index === 0 ? 'carousel-item active' : 'carousel-item'
								}
								key={index}
							>
								<Spinner animation="border" className="preloader-background" />
								<img
									className={
										visible ? 'd-block w-100' : 'd-block w-100 image-none'
									}
									onLoad={() => increment()}
									src={`/assets/backgrounds/${elem.background}.png`}
									alt="Third slide"
								/>
							</div>
						)
					})}
				</div>
			</div>
			{characters ? <BottomPersons visible={visible} /> : <></>}
			<Header />
			<TaskBox />
			<p className="background-changer__title">
				{
					props.state.questions.choises[props.match.params.questionid - 1]
						.selected.title
				}
			</p>
			<a
				className={
					disabled
						? 'carousel-control-prev pointer-disabled'
						: 'carousel-control-prev'
				}
				href="#carouselExampleControls"
				role="button"
				data-slide="prev"
				onClick={() => setBackground(0)}
			>
				<img
					className="background-changer__arrow arrow"
					src="/assets/arrows/arrowLeft.png"
				/>
				<span className="sr-only">Previous</span>
			</a>
			<a
				className={
					disabled
						? 'carousel-control-next pointer-disabled'
						: 'carousel-control-next'
				}
				href="#carouselExampleControls"
				role="button"
				data-slide="next"
				onClick={() => setBackground(1)}
			>
				<img
					className="background-changer__arrow arrow"
					src="/assets/arrows/arrowRight.png"
				/>
				<span className="sr-only">Next</span>
			</a>

			<div className="question__control">
				<Link
					to={{
						pathname: `/questions/${
							parseInt(props.match.params.questionid) + 1
						}`,
					}}
					className="control__button"
					onClick={() => answer()}
				>
					Confirm
				</Link>
			</div>
		</div>
	)
}

export default connect(
	state => ({ state }),
	dispatch => ({
		setSelect: payload => {
			dispatch({ type: 'SET_SELECT', payload })
		},
		nextSelect: payload => {
			dispatch({ type: 'NEXT_SELECT', payload })
		},
		prevSelect: payload => {
			dispatch({ type: 'PREV_SELECT', payload })
		},
		getAnswer: payload => {
			dispatch({ type: 'SET_ANSWER', payload })
		},
	}),
)(withRouter(BackgroundSelector))
