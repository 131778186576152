import React, { useState, useEffect } from 'react'
import Card from 'react-credit-cards'

import { withRouter } from 'react-router'
import { connect } from 'react-redux'

import './styles.css'

import {
	formatCreditCardNumber,
	formatCVC,
	formatExpirationDate,
	formatFormData,
} from './utils'

import 'react-credit-cards/es/styles-compiled.css'
import valid from 'card-validator'

function CreditCard(props) {
	const [number, setNumber] = useState('')
	const [name, setName] = useState('')
	const [expiry, setExpiry] = useState('')
	const [cvc, setCvc] = useState('')
	const [issuer, setIssuer] = useState('')
	const [focused, setFocused] = useState('')
	const [formData, setFormData] = useState(null)
	const [active, setActive] = useState(false)
	const [error, setError] = useState(false)
	useEffect(() => {
		if (
			valid.number(number).isValid &&
			name.length > 0 &&
			valid.expirationDate(expiry).isValid &&
			valid.cvv(cvc).isValid
		) {
			setActive(true)
		} else {
			setActive(false)
		}
	}, [number, expiry, cvc, name.length])

	const handleCallback = ({ issuer }, isValid) => {
		if (isValid) {
			setIssuer(issuer)
		}
	}
	const handleInputFocus = ({ target }) => {
		setFocused(target.name)
	}
	const handleInputChange = ({ target }) => {
		setError(false)
		if (target.name === 'number') {
			target.value = formatCreditCardNumber(target.value)
			setNumber(target.value)
		} else if (target.name === 'expiry') {
			target.value = formatExpirationDate(target.value)
			setExpiry(target.value)
		} else if (target.name === 'cvc') {
			target.value = formatCVC(target.value)
			setCvc(target.value)
		} else if (target.name === 'name') {
			setName(target.value)
		}
		setError(false)
	}
	const handleSubmit = e => {
		e.preventDefault()
		const formData = [...e.target.elements]
			.filter(d => d.name)
			.reduce((acc, d) => {
				acc[d.name] = d.value
				return acc
			}, {})
		setFormData(formData)
		// this.form.reset();
	}
	return (
		<div key="Payment">
			<div className="App-payment" style={{ paddingTop: 0 }}>
				<Card
					number={number}
					name={name}
					expiry={expiry}
					cvc={cvc}
					focused={focused}
					callback={handleCallback}
				/>
				<form onSubmit={handleSubmit}>
					<div className="form-group">
						<input
							type="tel"
							name="number"
							maxLength={19}
							className={
								error
									? 'form-control form-control-danger'
									: valid.number(number).isValid
									? 'form-control form-control-active'
									: 'form-control form-control-unactive'
							}
							placeholder="Card Number"
							pattern="[\d| ]{16,22}"
							required
							onChange={handleInputChange}
							onFocus={handleInputFocus}
						/>
					</div>
					<p className="numbers">E.g.: 49..., 51..., 36..., 37...</p>
					<div className="form-group">
						<input
							type="text"
							name="name"
							className={
								error
									? 'form-control form-control-danger'
									: name.length > 0
									? 'form-control form-control-active'
									: 'form-control form-control-unactive'
							}
							placeholder="CARDHOLDER"
							required
							onChange={handleInputChange}
							onFocus={handleInputFocus}
						/>
					</div>
					<div className="row">
						<div className="col-6 form-group">
							<input
								type="tel"
								name="expiry"
								maxLength={5}
								className={
									error
										? 'form-control form-control-danger'
										: valid.expirationDate(expiry).isValid
										? ' form-control form-control-active'
										: ' form-control form-control-unactive'
								}
								placeholder="MM/YY"
								pattern="\d\d/\d\d"
								required
								onChange={handleInputChange}
								onFocus={handleInputFocus}
							/>
						</div>
						<div className="col-6 form-group">
							<input
								type="tel"
								name="cvc"
								className={
									error
										? 'form-control form-control-danger'
										: valid.cvv(cvc).isValid
										? 'form-control form-control-active'
										: 'form-control form-control-unactive'
								}
								placeholder="CVC"
								pattern="\d{3,4}"
								required
								maxLength={3}
								onChange={handleInputChange}
								onFocus={handleInputFocus}
							/>
						</div>
					</div>
					<input type="hidden" name="issuer" value={issuer} />
				</form>
				{formData && (
					<div className="App-highlight">
						{formatFormData(formData).map((d, i) => (
							<div key={i}>{d}</div>
						))}
					</div>
				)}

				<button
					className={active ? 'pay__button-active' : 'pay__button-unactive'}
				>
					Pay
				</button>

				<p
					className="failed-payment"
					style={error ? { display: 'flex' } : { display: 'none' }}
				>
					<p></p>
				</p>
			</div>
		</div>
	)
}
export default connect(
	state => ({ state: state.user }),
	dispatch => ({
		setPay: () => {
			dispatch({ type: 'SET_PAY' })
		},
	}),
)(withRouter(CreditCard))
