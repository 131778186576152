import React from 'react'

import { Button } from '../../common'

import './styles.scss'

const Header = ({ handleScrollToPrice }) => {
	return (
		<div className="sale-header">
			<img
				src="/img/sale/logo.png"
				alt="my-fantasy-logo"
				className="sale-header__logo"
			/>
			<Button label="Start Free Trial" onClick={handleScrollToPrice} />
		</div>
	)
}

export { Header }
