import React, { useCallback, useEffect, useMemo, useState } from 'react'

import { Redirect, useHistory, useParams } from 'react-router-dom'

import { Hero, DialogText, Choice } from '../../components/story'
import { ampEvent, ampEventWithEventProperty } from '../../utils/amplitude'
import getLastBackground from '../../utils/getLastBackground'

import scenes from './scenesData.json'

import './styles.scss'

const MILISECONDS_IN_SECONDS = 1000
const DEFAULT_DELAY = 0.3

const StoryScreen = () => {
	const history = useHistory()
	const { id } = useParams()

	const [isShowHero, setIsShowHero] = useState(false)

	const [isReadyNextScene, setIsReadyNextScene] = useState(true)

	const currentScene = scenes[id]

	const lastBackground = useMemo(
		() => getLastBackground(scenes, currentScene.id),
		[currentScene.id],
	)

	const handleNextScene = useCallback(() => {
		if (
			(isReadyNextScene && !currentScene.choices) ||
			(isReadyNextScene && !currentScene.choices.length)
		) {
			ampEventWithEventProperty('question_answered', {
				question: id,
			})
			if (currentScene.nextId) {
				history.push(`/story/${currentScene.nextId}`)
				return
			}

			history.push(`/processing`)
		} else {
			setIsReadyNextScene(true)
		}
	}, [currentScene.choices, currentScene.nextId, history, isReadyNextScene])

	const handleMakeChoice = id => {
		if (id) {
			return history.push(`/story/${id}`)
		}
		history.push(`/processing`)
	}

	useEffect(() => {
		if (id === '2') {
			setTimeout(() => ampEvent('onboarding_shown'), 0)
		}
		setIsReadyNextScene(false)
		if (currentScene.text) {
			const timeoutDelay =
				(DEFAULT_DELAY + (currentScene.text.split('').length - 1) / 50) *
				MILISECONDS_IN_SECONDS

			const timeout = setTimeout(() => {
				setIsReadyNextScene(true)
			}, timeoutDelay)

			return () => {
				clearTimeout(timeout)
			}
		}
	}, [id])

	useEffect(() => {
		if (currentScene.character) {
			setIsShowHero(true)
		}
	}, [currentScene])

	useEffect(() => {
		document.body.style.overflow = 'hidden'

		return () => {
			document.body.style.overflow = 'visible'
		}
	}, [])

	if (
		currentScene.type === 'hero_selection' ||
		currentScene.type === 'background'
	) {
		return <Redirect to={`/story/${currentScene.nextId}`} />
	}

	return (
		<div
			className="story-background"
			onClick={handleNextScene}
			style={{
				backgroundImage: `url(/img/story/backgrounds/${lastBackground}.jpg)`,
			}}
		>
			{currentScene.type !== 'middle' ? (
				<Hero isShowHero={isShowHero} {...currentScene} />
			) : null}

			<div className="story">
				<DialogText
					isTutorial={currentScene.id < 5}
					isReadyNextScene={isReadyNextScene}
					setIsReadyNextScene={setIsReadyNextScene}
					choicesCount={currentScene.choices ? currentScene.choices.length : 0}
					{...currentScene}
				/>
				{currentScene.choices &&
					currentScene.choices.map((choice, idx) => (
						<Choice key={idx} handleMakeChoice={handleMakeChoice} {...choice} />
					))}
			</div>
		</div>
	)
}

export { StoryScreen }
