import React, { useState } from 'react'
import * as EmailValidator from 'email-validator'

import validateEmailFetch from '../../../api/validateEmailFetch'

import './styles.scss'
import AuthorizeForm from '../components/AuthorizeForm'
import WarningMessage from '../components/WarningMessage'
import AuthorizedUserInfo from '../components/AuthorizedUserInfo'
import { ampEvent } from '../../../utils/amplitude'

const ENTER_KEYCODE = 13

const AccountScreenContainer = () => {
	const [formValue, setFormValue] = useState('')
	const [isFoundEmail, setIsFoundEmail] = useState(true)
	const [isAuthorized, setIsAuthorized] = useState(false)

	const isValidEmail = EmailValidator.validate(formValue)

	const handleKeyboardSubmitEmail = e => {
		const { keyCode } = e
		if (keyCode === ENTER_KEYCODE && isValidEmail) {
			onSubmitEmail()
		}
	}

	const onSubmitEmail = async () => {
		const { premium } = await validateEmailFetch(formValue)
		if (premium) {
			ampEvent('authorization_completed')
			setIsFoundEmail(true)
			setIsAuthorized(true)
			return
		}
		setIsFoundEmail(false)
	}

	return (
		<div className="account">
			{!isFoundEmail && <WarningMessage />}
			<h2 className="account__header">My Account</h2>
			{isAuthorized ? (
				<AuthorizedUserInfo />
			) : (
				<AuthorizeForm
					isValidEmail={isValidEmail}
					formValue={formValue}
					setFormValue={setFormValue}
					handleKeyboardSubmitEmail={handleKeyboardSubmitEmail}
					onSubmitEmail={onSubmitEmail}
				/>
			)}
		</div>
	)
}

export default AccountScreenContainer
