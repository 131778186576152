import { combineReducers } from 'redux'
import {
	questionsReducer,
	STATE_KEY as QUESTIONS_STATE_KEY,
} from './reducers/questions'
import { userReducer, STATE_KEY as USER_STATE_KEY } from './reducers/user'

const rootReducer = combineReducers({
	[USER_STATE_KEY]: userReducer,
	[QUESTIONS_STATE_KEY]: questionsReducer,
})

export default rootReducer
