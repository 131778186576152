import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import './QuestionControlButton.scss'
import { connect } from 'react-redux'

function QuestionControl(props) {
	return (
		<div className="question__control">
			<Link
				to={{
					pathname: `/questions/${parseInt(props.match.params.questionid) + 1}`,
				}}
				className="control__button"
				onClick={() =>
					props.getAnswer({ id: props.match.params.questionid - 1 })
				}
			>
				Confirm
			</Link>
		</div>
	)
}
export default connect(
	state => ({ state }),
	dispatch => ({
		getAnswer: payload => {
			dispatch({ type: 'SET_ANSWER', payload })
		},
	}),
)(withRouter(QuestionControl))
