export const merchantDataHandler = (res, flow) => {
	return {
		merchantData: { ...res.merchantData },
		formParams: {
			submitButtonText: !flow ? 'Try Now' : 'Try for Free',
			formTypeClass: 'default',
			// cardBrands: ['visa', 'mastercard', 'diners-club', 'discover'],
		},
		styles: {
			submit_button: {
				'background-color': '#FA3269!important',
				':disabled': {
					'background-color': '#FFD1DD!important',
				},
			},
		},
	}
}
