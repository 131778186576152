import {
	setBill,
	setCardToken,
	setError,
	setPaypalToken,
} from '../../../../../redux/actions/user'

export function payPalHandler(history, dispatch, amount, pathname, bill, cb) {
	return function (e) {
		if (
			e?.detail?.data?.order?.status === 'approved' ||
			e?.detail?.data?.order?.status === 'void_ok'
		) {
			if (!bill) {
				cb()
			}
			dispatch(setBill({ type: 'PAYPAL', total: amount }))
			dispatch(
				setPaypalToken({ payPalToken: e?.detail?.data?.pay_form?.token }),
			)
			history.push({ pathname: '/success' })
			document.body.style.overflow = 'visible'
		}
		if (
			(e?.type === 'order-processed' &&
				e?.detail?.data?.order?.status === 'declined') ||
			e?.detail?.data?.order?.status === 'auth_failed'
		) {
			dispatch(
				setError({
					error: e.detail?.data?.error?.recommended_message_for_user,
				}),
			)
			history.push({ pathname: '/error', state: { from: pathname } })
			document.body.style.overflow = 'visible'
		}
	}
}
export function cardHandler(history, dispatch, amount, pathname, bill, cb) {
	return function (e) {
		if (
			e?.data?.response?.order?.status === 'approved' ||
			e?.data?.response?.order?.status === 'void_ok'
		) {
			if (!bill) {
				cb()
			}
			console.log('in card handler    ', amount)
			dispatch(setBill({ type: 'CARD', total: amount }))
			dispatch(
				setCardToken({
					cardToken:
						e.data.response?.transactions[
							Object.keys(e.data?.response?.transactions)[0]
						]?.card?.card_token?.token ||
						e.data?.response?.transactions[
							Object.keys(e.data?.response?.transactions)[1]
						]?.card?.card_token?.token,
				}),
			)

			history.push({ pathname: '/success' })
			document.body.style.overflow = 'visible'
		}
		if (
			e.data.type === 'orderStatus' &&
			(e.data.response.order.status === 'declined' ||
				e.data.response.order.status === 'auth_failed')
		) {
			dispatch(
				setError({
					error: e.data.response.error.recommended_message_for_user,
				}),
			)

			history.push({ pathname: '/error', state: { from: pathname } })
			document.body.style.overflow = 'visible'
		}
	}
}
