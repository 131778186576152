import React, { useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import './Personality.scss'

function Personality(props) {
	const [menu, setMenu] = useState(false)
	const [countdown, setCountDown] = useState('09:59')
	const [time] = useState(Date.now() + 1000 * 60 * 10)
	useEffect(() => {
		setInterval(() => {
			const minutes = new Date(new Date(time) - new Date()).getMinutes()
			const seconds = new Date(new Date(time) - new Date()).getSeconds()
			setCountDown(
				`${minutes < 10 ? '0' : ''}${minutes}:${
					seconds < 10 ? '0' : ''
				}${seconds}`,
			)
		}, 1000)
	}, [time])
	return (
		<>
			<header className="home__header">
				<Link to="/">
					<img className="home__logo" src="/logo.png" />
				</Link>
				<div className="home-header__rightside">
					<i
						className="fas fa-bars home-header__icon"
						onClick={() => setMenu(true)}
					></i>
				</div>
			</header>
			<div className="ready-container">
				<img
					src="/assets/personality/dialog.png"
					className="ready-container__img-dialog"
				/>
				<div className="ready-container-character">
					<img
						className="ready-container-character__img"
						src={`/assets/mainhero/${
							props.state.questions.choises[0].selected || 'female'
						}/hair/${props.state.questions.choises[2].selected}back.png`}
						alt="Первый слайд"
					/>
					<img
						className="ready-container-character__img"
						src={`/assets/mainhero/${
							props.state.questions.choises[0].selected || 'female'
						}/body/${props.state.questions.choises[1].selected}.png`}
						alt="Первый слайд"
					/>
					<img
						className="ready-container-character__img"
						src={`/assets/mainhero/${
							props.state.questions.choises[0].selected || 'female'
						}/clothes/${props.state.questions.choises[3].selected}.png`}
						alt="Первый слайд"
					/>
					<img
						className="ready-container-character__img"
						src={`/assets/mainhero/${
							props.state.questions.choises[0].selected || 'female'
						}/faces/${props.state.questions.choises[1].selected}.png`}
						alt="Первый слайд"
					/>
					<img
						className="ready-container-character__img"
						src={`/assets/mainhero/${
							props.state.questions.choises[0].selected || 'female'
						}/hair/${props.state.questions.choises[2].selected}.png`}
						alt="Первый слайд"
					/>
				</div>
				<img
					src="/assets/personality/bottom.png"
					className="ready-container__img-bottom"
				/>
				<img
					src="/assets/personality/dialog.png"
					className="ready-container__img-dialog"
				/>
				<p className="ready-container__text">
					{props.state.questions.choises[4].name}
				</p>
				<div
					className="secret-fantasy__img-wrapper2"
					style={{
						position: 'absolute',
						bottom: 100,
						width: '100%',
						textAlign: 'center',
						display: 'flex',
						alignItems: 'center',
						justifyContent: 'center',
					}}
				>
					<a
						className="control__button"
						href="#getstory"
						style={{
							zIndex: 1,
							height: 48,
							position: 'absolute',
							margin: 0,
							left: 0,
							right: 0,
							bottom: 0,
							top: 0,
							marginLeft: 'auto',
							marginRight: 'auto',
							marginTop: 'auto',
							marginBottom: 'auto',
							color: 'white',
						}}
					>
						Start reading now!
					</a>
					<img
						src="/assets/home/main.png"
						className="animation"
						style={{ width: '90%', height: 90, top: 30 }}
					/>
				</div>
				<img
					src="/assets/personality/background.png"
					className="ready-container__img"
				/>
			</div>
			<div className="secret-fantasy">
				<img
					src={`/assets/personality/epilog/epilog${
						props.state.questions.choises[19].answer || 2
					}.png`}
					className="secret-fantasy__img"
				/>
				<div className="secret-fantasy__img-wrapper">
					<a
						className="control__button"
						href="#getstory"
						style={{ height: 48 }}
					>
						Read now
					</a>
				</div>
				<img src="/assets/personality/premium.png" className="premium__img" />
				<p className="text-extralarge">Unlimited access to Fantasy Rooms</p>
				<p className="text-semilarge">Try more fantasies in the app: </p>
				<p className="text-small">
					You are competing for a champion title in the Chess National Cup. But
					it’s hard to focus on the game, when your opponent is your lover you
					haven’t seen in a long time after a hard break up...
				</p>
				<video
					width="100%"
					autoPlay
					muted
					loop
					controls
					style={{ marginTop: 41 }}
				>
					<source src="./chess.mp4" type="video/mp4" />
				</video>

				<a
					className="control__button"
					href="#getstory"
					style={{ zIndex: 1, height: 48, marginTop: 40, width: '82%' }}
				>
					Play now
				</a>
				<img
					src="/assets/personality/Image.png"
					className="premium__img"
					style={{ marginTop: 35 }}
				/>
				<p className="text-large">Money-back guarantee</p>
				<p className="text-medium">
					There’s no doubt that our books will bring you pleasure and
					satisfaction only. We’ve got some of the best narrative designers in
					the world, after all.
					<br />
					<br /> Although, if you feel that our top-notch books don’t quite meet
					your taste, we guarantee to give you money back in 30 days. But let’s
					face it, you’re gonna love it!
				</p>
				<p className="text-large">
					Get your customized interactive story for 30 days
				</p>
				<img
					src={`/assets/personality/success/${
						props.state.questions.choises[19].answer || 2
					}.png`}
					className="premium__img"
					style={{ width: '100%', marginTop: 17 }}
				/>
				<img
					src="/assets/personality/Price.png"
					className="premium__img"
					style={{ width: '40%', marginTop: 20 }}
				/>
				<div className="countdown">
					<p className="timer">{countdown}</p>
					<p className="text">Your offer expires in</p>
				</div>
				<div className="features">
					<div className="feature">
						<img
							src="/assets/personality/pick.png"
							style={{ width: 11, height: 8 }}
						/>
						<p className="feature__text"> Unlimited access for 30 days</p>
					</div>
					<div className="feature">
						<img
							src="/assets/personality/pick.png"
							style={{ width: 11, height: 8 }}
						/>
						<p className="feature__text">Premium account</p>
					</div>
					<div className="feature">
						<img
							src="/assets/personality/pick.png"
							style={{ width: 11, height: 8 }}
						/>
						<p className="feature__text">Money-back guarantee</p>
					</div>
				</div>
				<div
					className="secret-fantasy__img-wrapper2"
					style={{ marginBottom: 25 }}
				>
					<Link
						className="control__button"
						to="/checkout"
						id="getstory"
						style={{ zIndex: 1, height: 48, position: 'absolute' }}
					>
						Get my customized story
					</Link>
					<img
						src="/assets/home/main.png"
						className="animation"
						style={{ width: '90%', height: 90 }}
					/>
				</div>
			</div>
			<div className="home__footer">
				<div className="footer__grid">
					<div className="footer__section">
						<h3 className="section__h">Legacy</h3>
						<p className="section__p">Terms of Service</p>
						<p className="section__p">Privacy Policy</p>
					</div>
					<div className="footer__section">
						<h3 className="section__h">Help</h3>
						<p className="section__p">Contact Us</p>
						<p className="section__p">FAQ</p>
					</div>
				</div>
				<p className="footer__text">
					GM Unicorn Corporation Limited
					<br /> Florinis 7, Greg Tower, 2nd Floor, 1065, Nicosia, Cyprus{' '}
				</p>
			</div>
			<div className={menu ? 'home__menu' : 'home__menu none'}>
				<div className="menu__header">
					<i
						className="fas fa-times"
						onClick={() => setMenu(false)}
						style={{ fontSize: 25 }}
					></i>
				</div>
				<div className="menu__body">
					<p className="menu-body__text">FAQ</p>
					<p className="menu-body__text">Privacy Policy</p>
					<p className="menu-body__text">Terms of service</p>
				</div>
				<hr />
				<div className="menu__footer">
					<p className="menu-footer__text">Contact us</p>
					<p className="menu-footer__text">Support@my-fantasy.life</p>
				</div>
			</div>
		</>
	)
}
export default connect(
	state => ({ state }),
	dispatch => ({
		setSelect: payload => {
			dispatch({ type: 'SET_QUIZ_SELECT', payload })
		},
	}),
)(withRouter(Personality))
