import { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import './Sliderassets.scss'
import { TaskBox } from '../exporter'
import { Spinner } from 'react-bootstrap'

import { Link } from 'react-router-dom'

function SliderAssets(props) {
	const [reload, setReload] = useState(false)
	const [loaded5, setLoaded5] = useState(false)
	const [disabled, setDisabled] = useState(true)
	const [loaded, setLoaded] = useState(0)
	const [count, setCount] = useState(0)
	useEffect(() => {
		setReload(!reload)
	}, [])

	useEffect(() => {
		setCount(
			props.state.questions.choises[props.match.params.questionid - 1]
				.sliderTemplates.length *
				5 -
				(props.match.params.questionid - 1) * 5,
		)
		if (count === loaded && loaded !== 0) {
			setLoaded5(true)
			setDisabled(false)
		}
	})
	const answer = () => {
		setLoaded5(false)
		setLoaded(0)
		props.getAnswer({ id: props.match.params.questionid - 1 })
		setDisabled(true)
	}
	useEffect(() => {
		props.setSelect({ id: props.match.params.questionid - 1 })
		document.getElementsByClassName('active')[0].classList.remove('active')
		document
			.getElementsByClassName('carousel-item__custom')[0]
			.classList.add('active')
	}, [props])
	const setCounter = iterator => {
		setDisabled(true)
		setTimeout(() => {
			setDisabled(false)
		}, 600)
		if (iterator) {
			props.nextSelect({ id: props.match.params.questionid - 1 })
		} else {
			props.prevSelect({ id: props.match.params.questionid - 1 })
		}
	}
	return (
		<>
			<TaskBox />

			<div
				id="carouselExampleControls"
				className="carousel slide carousel__custom"
				data-ride="carousel"
				data-interval="false"
				data-touch="true"
			>
				<a
					className={
						disabled
							? 'carousel-control-prev carousel-control__custom pointer-disabled'
							: 'carousel-control-prev carousel-control__custom'
					}
					href="#carouselExampleControls"
					role="button"
					data-slide="prev"
					onClick={() => setCounter(0)}
				>
					<span className="sr-only">Previous</span>
					<img
						src="/assets/arrows/arrowLeft.png"
						className="arrow left-arrow"
					/>
				</a>
				<div className="carousel-inner carousel-inner__custom">
					{props.state.questions.choises[
						props.match.params.questionid - 1
					].sliderTemplates.map((item, index) => {
						switch (
							props.state.questions.choises[props.match.params.questionid - 1]
								.tag
						) {
							case 'gender': {
								return (
									<div
										className={
											index === 0
												? 'carousel-item active carousel-item__custom'
												: 'carousel-item carousel-item__custom'
										}
										key={index}
									>
										<div className="carousel-block__custom">
											<Spinner animation="border" className="preloader" />
											<img
												className={
													loaded5
														? 'carousel-image'
														: 'carousel-image image-none'
												}
												onLoad={() => setLoaded(loaded + 1)}
												src={`/assets/mainhero/${
													props.state.questions.choises[0].sliderTemplates[
														index
													] || 'female'
												}/hair/hair1back.png`}
												alt="Первый слайд"
											/>
											<img
												className={
													loaded5
														? 'carousel-image'
														: 'carousel-image image-none'
												}
												onLoad={() => setLoaded(loaded + 1)}
												src={`/assets/mainhero/${
													props.state.questions.choises[0].sliderTemplates[
														index
													] || 'female'
												}/body/body1.png`}
												alt="Первый слайд"
											/>
											<img
												className={
													loaded5
														? 'carousel-image'
														: 'carousel-image image-none'
												}
												onLoad={() => setLoaded(loaded + 1)}
												src={`/assets/mainhero/${
													props.state.questions.choises[0].sliderTemplates[
														index
													] || 'female'
												}/clothes/clothes1.png`}
												alt="Первый слайд"
											/>
											<img
												className={
													loaded5
														? 'carousel-image'
														: 'carousel-image image-none'
												}
												onLoad={() => setLoaded(loaded + 1)}
												src={`/assets/mainhero/${
													props.state.questions.choises[0].sliderTemplates[
														index
													] || 'female'
												}/faces/body1.png`}
												alt="Первый слайд"
											/>
											<img
												className={
													loaded5
														? 'carousel-image'
														: 'carousel-image image-none'
												}
												onLoad={() => setLoaded(loaded + 1)}
												src={`/assets/mainhero/${
													props.state.questions.choises[0].sliderTemplates[
														index
													] || 'female'
												}/hair/hair1.png`}
												alt="Первый слайд"
											/>
										</div>
									</div>
								)
							}
							case 'body': {
								return (
									<div
										className={
											index === 0
												? 'carousel-item active carousel-item__custom'
												: 'carousel-item carousel-item__custom'
										}
										key={index}
									>
										<div className="carousel-block__custom">
											<Spinner animation="border" className="preloader" />
											<img
												className={
													loaded5
														? 'carousel-image'
														: 'carousel-image image-none'
												}
												onLoad={() => setLoaded(loaded + 1)}
												src={`/assets/mainhero/${
													props.state.questions.choises[0].selected || 'female'
												}/hair/hair1back.png`}
												alt="Первый слайд"
											/>
											<img
												className={
													loaded5
														? 'carousel-image'
														: 'carousel-image image-none'
												}
												onLoad={() => setLoaded(loaded + 1)}
												src={`/assets/mainhero/${
													props.state.questions.choises[0].selected || 'female'
												}/body/${
													props.state.questions.choises[1].sliderTemplates[
														index
													] || 'body1'
												}.png`}
												alt="Первый слайд"
											/>
											<img
												className={
													loaded5
														? 'carousel-image'
														: 'carousel-image image-none'
												}
												onLoad={() => setLoaded(loaded + 1)}
												src={`/assets/mainhero/${
													props.state.questions.choises[0].selected || 'female'
												}/clothes/clothes1.png`}
												alt="Первый слайд"
											/>
											<img
												className={
													loaded5
														? 'carousel-image'
														: 'carousel-image image-none'
												}
												onLoad={() => setLoaded(loaded + 1)}
												src={`/assets/mainhero/${
													props.state.questions.choises[0].selected || 'female'
												}/faces/${
													props.state.questions.choises[1].sliderTemplates[
														index
													] || 'body1'
												}.png`}
												alt="Первый слайд"
											/>
											<img
												className={
													loaded5
														? 'carousel-image'
														: 'carousel-image image-none'
												}
												onLoad={() => setLoaded(loaded + 1)}
												src={`/assets/mainhero/${
													props.state.questions.choises[0].selected || 'female'
												}/hair/hair1.png`}
												alt="Первый слайд"
											/>
										</div>
									</div>
								)
							}
							case 'hair': {
								return (
									<div
										className={
											index === 0
												? 'carousel-item active carousel-item__custom'
												: 'carousel-item carousel-item__custom'
										}
										key={index}
									>
										<div className="carousel-block__custom">
											<Spinner animation="border" className="preloader" />
											<img
												className={
													loaded5
														? 'carousel-image'
														: 'carousel-image image-none'
												}
												onLoad={() => setLoaded(loaded + 1)}
												src={`/assets/mainhero/${
													props.state.questions.choises[0].selected ||
													props.state.questions.choises[0].sliderTemplates[
														index
													] ||
													'female'
												}/hair/${
													props.state.questions.choises[2].sliderTemplates[
														index
													] || 'hair1'
												}back.png`}
												alt="Первый слайд"
											/>
											<img
												className={
													loaded5
														? 'carousel-image'
														: 'carousel-image image-none'
												}
												onLoad={() => setLoaded(loaded + 1)}
												src={`/assets/mainhero/${
													props.state.questions.choises[0].selected ||
													props.state.questions.choises[0].sliderTemplates[
														index
													] ||
													'female'
												}/body/${
													props.state.questions.choises[1].selected || 'body1'
												}.png`}
												alt="Первый слайд"
											/>
											<img
												className={
													loaded5
														? 'carousel-image'
														: 'carousel-image image-none'
												}
												onLoad={() => setLoaded(loaded + 1)}
												src={`/assets/mainhero/${
													props.state.questions.choises[0].selected ||
													props.state.questions.choises[0].sliderTemplates[
														index
													] ||
													'female'
												}/clothes/clothes1.png`}
												alt="Первый слайд"
											/>
											<img
												className={
													loaded5
														? 'carousel-image'
														: 'carousel-image image-none'
												}
												onLoad={() => setLoaded(loaded + 1)}
												src={`/assets/mainhero/${
													props.state.questions.choises[0].selected ||
													props.state.questions.choises[0].sliderTemplates[
														index
													] ||
													'female'
												}/faces/${
													props.state.questions.choises[1].selected || 'body1'
												}.png`}
												alt="Первый слайд"
											/>
											<img
												className={
													loaded5
														? 'carousel-image'
														: 'carousel-image image-none'
												}
												onLoad={() => setLoaded(loaded + 1)}
												src={`/assets/mainhero/${
													props.state.questions.choises[0].selected ||
													props.state.questions.choises[0].sliderTemplates[
														index
													] ||
													'female'
												}/hair/${
													props.state.questions.choises[2].sliderTemplates[
														index
													] || 'hair1'
												}.png`}
												alt="Первый слайд"
											/>
										</div>
									</div>
								)
							}
							case 'clothes': {
								return (
									<div
										className={
											index === 0
												? 'carousel-item active carousel-item__custom'
												: 'carousel-item carousel-item__custom'
										}
										key={index}
									>
										<div className="carousel-block__custom">
											<Spinner animation="border" className="preloader" />
											<img
												className={
													loaded5
														? 'carousel-image'
														: 'carousel-image image-none'
												}
												onLoad={() => setLoaded(loaded + 1)}
												src={`/assets/mainhero/${
													props.state.questions.choises[0].selected ||
													props.state.questions.choises[0].sliderTemplates[
														index
													] ||
													'female'
												}/hair/${
													props.state.questions.choises[2].selected || 'hair1'
												}back.png`}
												alt="Первый слайд"
											/>
											<img
												className={
													loaded5
														? 'carousel-image'
														: 'carousel-image image-none'
												}
												onLoad={() => setLoaded(loaded + 1)}
												src={`/assets/mainhero/${
													props.state.questions.choises[0].selected ||
													props.state.questions.choises[0].sliderTemplates[
														index
													] ||
													'female'
												}/body/${
													props.state.questions.choises[1].selected || 'body1'
												}.png`}
												alt="Первый слайд"
											/>
											<img
												className={
													loaded5
														? 'carousel-image'
														: 'carousel-image image-none'
												}
												onLoad={() => setLoaded(loaded + 1)}
												src={`/assets/mainhero/${
													props.state.questions.choises[0].selected ||
													props.state.questions.choises[0].sliderTemplates[
														index
													] ||
													'female'
												}/clothes/${
													props.state.questions.choises[3].sliderTemplates[
														index
													] || 'clothes1'
												}.png`}
												alt="Первый слайд"
											/>
											<img
												className={
													loaded5
														? 'carousel-image'
														: 'carousel-image image-none'
												}
												onLoad={() => setLoaded(loaded + 1)}
												src={`/assets/mainhero/${
													props.state.questions.choises[0].selected ||
													props.state.questions.choises[0].sliderTemplates[
														index
													] ||
													'female'
												}/faces/${
													props.state.questions.choises[1].selected || 'body1'
												}.png`}
												alt="Первый слайд"
											/>
											<img
												className={
													loaded5
														? 'carousel-image'
														: 'carousel-image image-none'
												}
												onLoad={() => setLoaded(loaded + 1)}
												src={`/assets/mainhero/${
													props.state.questions.choises[0].selected ||
													props.state.questions.choises[0].sliderTemplates[
														index
													] ||
													'female'
												}/hair/${
													props.state.questions.choises[2].selected || 'hair1'
												}.png`}
												alt="Первый слайд"
											/>
										</div>
									</div>
								)
							}

							default: {
								return <h2>Hello</h2>
							}
						}
					})}
				</div>
				<a
					className={
						disabled
							? 'carousel-control-next carousel-control__custom pointer-disabled'
							: 'carousel-control-next carousel-control__custom'
					}
					href="#carouselExampleControls"
					role="button"
					data-slide="next"
					onClick={() => setCounter(1)}
				>
					<span className="sr-only">Next</span>
					<img
						src="/assets/arrows/arrowRight.png"
						className="arrow right-arrow"
					/>
				</a>
			</div>
			<div className="question__control">
				<Link
					to={{
						pathname: `/questions/${
							parseInt(props.match.params.questionid) + 1
						}`,
					}}
					className={
						disabled || !loaded5
							? 'control__button pointer-disabled'
							: 'control__button'
					}
					onClick={answer}
				>
					Confirm
				</Link>
			</div>
		</>
	)
}
export default connect(
	state => ({ state }),
	dispatch => ({
		newGame: () => {
			dispatch({ type: 'NEW_GAME' })
		},
		setSelect: payload => {
			dispatch({ type: 'SET_SELECT', payload })
		},
		nextSelect: payload => {
			dispatch({ type: 'NEXT_SELECT', payload })
		},
		prevSelect: payload => {
			dispatch({ type: 'PREV_SELECT', payload })
		},
		getAnswer: payload => {
			dispatch({ type: 'SET_ANSWER', payload })
		},
	}),
)(withRouter(SliderAssets))
