import React, { useEffect, useState } from 'react'
import { useHistory, useLocation } from 'react-router-dom'
import { ampEvent } from '../../../utils/amplitude'
import BurgerMenu from './component/BurgerMenu'
import Header from './component/Header'

// const HEADER_WIDTH = 69

const HeaderProvider = ({ children }) => {
	const history = useHistory()
	const { pathname } = useLocation()
	const [isOpenBurgerMenu, setIsOpenBurgerMenu] = useState(false)

	const backButtonAllowedPathnames = [
		'/upsale',
		'/faq',
		'/privacy-policy',
		'/account',
		'/terms-of-use',
		'/subscription-terms',
		'/money-back',
	]

	const transparentHeaderPathnames = [
		'/email',
		'/story',
		'/question',
		'/account',
		'/error',
	]
	const isHeaderTransparent = transparentHeaderPathnames.find(item =>
		pathname.includes(item),
	)

	const toggleBurgerMenu = () => {
		if (!isOpenBurgerMenu) ampEvent('menu_shown')

		setIsOpenBurgerMenu(!isOpenBurgerMenu)
	}

	const handleCloseBurgerMenu = () => setIsOpenBurgerMenu(false)

	const isShowBackButton =
		!isOpenBurgerMenu && backButtonAllowedPathnames.includes(pathname)

	const handlePrevButtonClick = () => {
		if (pathname.includes('/question') && pathname !== '/question/2') {
			const lastDashIndex = pathname.lastIndexOf('/')
			const questionId = pathname.slice(lastDashIndex + 1)
			return history.push(`/question/${+questionId - 1}`)
		}

		return history.goBack()
	}

	// useEffect(() => {
	// 	window.addEventListener('scroll', changeNav)
	// }, [])

	useEffect(() => {
		if (isOpenBurgerMenu) {
			document.body.style.overflow = 'hidden'
			return
		}
		document.body.style.overflow = 'visible'
	}, [isOpenBurgerMenu])

	return (
		<>
			<Header
				onClickBackHandler={handlePrevButtonClick}
				isShowBackButton={isShowBackButton}
				isOpenBurgerMenu={isOpenBurgerMenu}
				toggleBurgerMenu={toggleBurgerMenu}
				isStory={pathname.includes('/story')}
				isTransparent={isHeaderTransparent}
			/>
			<BurgerMenu
				isOpen={isOpenBurgerMenu}
				handleCloseBurgerMenu={handleCloseBurgerMenu}
			/>
			{children}
		</>
	)
}

export default HeaderProvider
