import {
	NEW_GAME,
	NEXT_SELECT,
	PREV_SELECT,
	SET_ANSWER,
	SET_BOOK,
	SET_NAME,
	SET_QUIZ_SELECT,
	SET_SELECT,
} from '../../actions/questions'
import initialState from './initialState'

export const STATE_KEY = 'questions'

export const questionsReducer = (state = initialState, action) => {
	const { payload } = action

	switch (action.type) {
		case SET_SELECT: {
			const { id } = payload
			state.choises[id].selected = state.choises[id].sliderTemplates[0]

			return state
		}
		case NEW_GAME: {
			return initialState
		}
		case NEXT_SELECT: {
			const { id } = payload

			if (
				state.choises[id].sliderTemplates.findIndex(
					elem => elem === state.choises[id].selected,
				) +
					1 >=
				state.choises[id].sliderTemplates.length
			) {
				state.choises[id].selected = state.choises[id].sliderTemplates[0]
			} else {
				state.choises[id].selected =
					state.choises[id].sliderTemplates[
						state.choises[id].sliderTemplates.findIndex(
							elem => elem === state.choises[id].selected,
						) + 1
					]
			}

			return state
		}
		case SET_QUIZ_SELECT: {
			const { id } = payload

			state.choises[id].selected = action.payload.value

			return state
		}
		case SET_NAME: {
			const { id } = payload

			state.choises[id].name = action.payload.name

			return state
		}
		case PREV_SELECT: {
			const { id } = payload

			if (
				state.choises[id].sliderTemplates.findIndex(
					elem => elem === state.choises[id].selected,
				) -
					1 <
				0
			) {
				state.choises[id].selected =
					state.choises[id].sliderTemplates[
						state.choises[id].sliderTemplates.length - 1
					]
			} else {
				state.choises[id].selected =
					state.choises[id].sliderTemplates[
						state.choises[id].sliderTemplates.findIndex(
							elem => elem === state.choises[id].selected,
						) - 1
					]
			}

			return state
		}
		case SET_BOOK: {
			const { id } = payload

			state.choises[id].answered = true
			state.choises[id].answer = action.payload.number

			return state
		}

		case SET_ANSWER: {
			const { id } = payload

			state.choises[id].answered = true

			return state
		}
		default:
			return state
	}
}
