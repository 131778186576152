import React, { useEffect, useMemo, useState } from 'react'
import { useParams } from 'react-router-dom'

import './styles.scss'

const DEFAULT_DELAY = 0.3

const DialogText = ({
	isNewType,
	character,
	text,
	type,
	isTutorial,
	choicesCount,
	isReadyNextScene,
}) => {
	const { id } = useParams()

	const [isBouncingTooltip, setBouncingTooltip] = useState(false)

	const style = useMemo(() => {
		const tutorialMargin = isTutorial ? 19 : 0

		if (type === 'middle') {
			return {
				margin: '0 auto',
			}
		}
		if (type === 'Left') {
			return {
				marginTop:
					choicesCount * 50 +
					choicesCount * 10 -
					(choicesCount - 1) * 5 +
					tutorialMargin,
				marginLeft: '5%',
				marginRigh: 'auto',
			}
		}
		if (type === 'Right') {
			return {
				marginTop:
					choicesCount * 50 +
					choicesCount * 10 -
					(choicesCount - 1) * 5 +
					tutorialMargin,
				marginRight: '5%',
				marginLeft: 'auto',
			}
		}
	}, [type, isTutorial, choicesCount])

	useEffect(() => {
		if (isTutorial) {
			setBouncingTooltip(false)
			setTimeout(() => {
				setBouncingTooltip(true)
			}, 100)
		}
	}, [id, isTutorial])

	return (
		<div
			className={`dialog-text ${isNewType ? 'dialog-text--fade-in' : ''}`}
			style={style}
		>
			<span className="dialog-text__character">
				{type === 'middle'
					? '...'
					: character === 'MAINHERO'
					? 'Ann'
					: character}
			</span>
			<div className="dialog-text__text">
				<p>
					{!isReadyNextScene
						? text.split('').map((letter, index) => {
								return (
									<span
										aria-hidden="true"
										key={letter + index + Math.random()}
										style={{ animationDelay: DEFAULT_DELAY + index / 50 + 's' }}
									>
										{letter}
									</span>
								)
						  })
						: text.split('').map((letter, index) => {
								return (
									<span key={letter + Math.random() + index}>{letter}</span>
								)
						  })}
				</p>
			</div>
			{isTutorial ? (
				<span
					className={`dialog-text__tooltip ${
						isBouncingTooltip ? 'dialog-text__tooltip--bouncing' : ''
					}`}
				>
					Tap to continue
				</span>
			) : null}
		</div>
	)
}

export { DialogText }
