import React from 'react'
import { withRouter } from 'react-router'
import './TaskBox.scss'
import { connect } from 'react-redux'

function TaskBox(props) {
	return (
		<div className="question__field">
			<div className="question__header">
				<img src="/assets/etc/Vector.png" className="header__img" />
				<h3 className="header__text">
					Choice {props.match.params.questionid}/
					{props.state.questions.choises.length}
				</h3>
			</div>
			<h3 className="question__text">
				{
					props.state.questions.choises[props.match.params.questionid - 1]
						.question
				}
			</h3>
		</div>
	)
}

export default connect(state => ({ state }))(withRouter(TaskBox))
