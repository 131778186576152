import * as queryString from 'query-string'
import { sendConfirmEmail, setEmail, setFlow } from '../redux/actions/user'

export function QueryHandler(query, handlers) {
	const data = queryString.parse(query)
	if (data.modal === 'true') {
		handlers.setIsActiveModal(true)
	}
	if (data.message === 'true') {
		handlers.dispatch(sendConfirmEmail())
	}
	if (data.flow === '' || data.flow) {
		handlers.dispatch(
			setFlow({
				flow: data.flow,
			}),
		)
	}
	if (data.email) {
		handlers.dispatch(setEmail({ email: data.email }))
	} else {
		handlers.history.push('/')
	}
}

export const SendConfirmEmail = async (body, dispatch) => {
	await fetch(`${process.env.REACT_APP_SERVER_URL}/api/sendios/email`, {
		method: 'POST',
		headers: {
			auth: '135017',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	}).then(() => dispatch(sendConfirmEmail()))
}

export const SendSuccessEmail = async email => {
	const body = {
		email,
		type_id: 2,
		project_id: process.env.REACT_APP_PROJECT_ID,
		report_url: `${window.location.origin}/success?billed=true`,
	}

	await fetch(`${process.env.REACT_APP_SERVER_URL}/api/sendios/email`, {
		method: 'POST',
		headers: {
			auth: '135017',
			'Content-Type': 'application/json',
		},
		body: JSON.stringify(body),
	})
}
