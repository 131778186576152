import './App.css'
import React, { useEffect } from 'react'
import * as queryString from 'query-string'
import * as cookie from 'cookie'

import { useLocation } from 'react-router-dom'

import { ampSetUserProperty } from './utils/amplitude'
import Router from './router'
import { Media } from './components/common'
import { useDispatch } from 'react-redux'
import { setFbc, setFbp } from './redux/actions/user'

const TIMEOUT = 2000

const App = () => {
	const { pathname, search } = useLocation()
	const dispatch = useDispatch()
	const params = queryString.parse(search)

	useEffect(() => {
		window.scrollTo(0, 0)
		Object.keys(params).forEach(elem => {
			if (elem === 'flow' && !params[elem]) {
				return
			}
			ampSetUserProperty(elem, params[elem])
		})
	}, [pathname])

	useEffect(() => {
		window.history.scrollRestoration = 'manual'

		setTimeout(() => {
			const cookies = cookie.parse(document.cookie)
			dispatch(setFbc({ fbc: cookies._fbc || '' }))
			dispatch(setFbp({ fbp: cookies._fbp || '' }))
		}, TIMEOUT)
	}, [])

	return (
		<>
			<Router type={params.flow} />
			<Media />
		</>
	)
}

export default App
