import React, { useEffect } from 'react'
import { Form } from '../../components/email'
import { ampEvent } from '../../utils/amplitude'

import './styles.scss'

const EmailScreen = () => {
	useEffect(() => {
		document.body.style.overflow = 'hidden'

		return () => {
			document.body.style.overflow = 'visible'
		}
	})

	useEffect(() => {
		ampEvent('email shown')
	}, [])

	return (
		<div className="email">
			<Form />
		</div>
	)
}

export { EmailScreen }
