import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { TaskBox } from '../exporter'
import { Link } from 'react-router-dom'
import './CharacterQuiz.scss'

function CharacterQuiz(props) {
	const [select, setSelect] = useState(false)
	const [disabled, setDisabled] = useState(true)
	useEffect(() => {
		if (select === false) {
			setDisabled(true)
		} else {
			setDisabled(false)
		}
	})

	useEffect(() => {
		props.setSelect({ id: props.match.params.questionid - 1, value: select })
	})
	return (
		<>
			<TaskBox />
			<div className="quizes">
				{parseInt(props.match.params.questionid) === 6
					? props.state.questions.choises[0].selected === 'female'
						? props.state.questions.choises[5].selectors.map((elem, index) => {
								return (
									<div
										className={
											select === index
												? 'quizes__block active__quiz'
												: 'quizes__block'
										}
										key={index}
										onClick={() => setSelect(index)}
									>
										<h3 className="quizes__header">{elem.header}</h3>
										<p className="quizes__value">{elem.value}</p>
									</div>
								)
						  })
						: props.state.questions.choises[11].selectors.map((elem, index) => {
								return (
									<div
										className={
											select === index
												? 'quizes__block active__quiz'
												: 'quizes__block'
										}
										key={index}
										onClick={() => setSelect(index)}
									>
										<h3 className="quizes__header">{elem.header}</h3>
										<p className="quizes__value">{elem.value}</p>
									</div>
								)
						  })
					: props.state.questions.choises[6].selected === 'female'
					? props.state.questions.choises[5].selectors.map((elem, index) => {
							return (
								<div
									className={
										select === index
											? 'quizes__block active__quiz'
											: 'quizes__block'
									}
									key={index}
									onClick={() => setSelect(index)}
								>
									<h3 className="quizes__header">{elem.header}</h3>
									<p className="quizes__value">{elem.value}</p>
								</div>
							)
					  })
					: props.state.questions.choises[11].selectors.map((elem, index) => {
							return (
								<div
									className={
										select === index
											? 'quizes__block active__quiz'
											: 'quizes__block'
									}
									key={index}
									onClick={() => setSelect(index)}
								>
									<h3 className="quizes__header">{elem.header}</h3>
									<p className="quizes__value">{elem.value}</p>
								</div>
							)
					  })}

				{() => {
					if (props.match.params.questionid === 6) {
						if (props.state.questions.choises[0] === 'female') {
							props.state.questions.choises[5].selectors.map((elem, index) => {
								return (
									<div
										className={
											select === index
												? 'quizes__block active__quiz'
												: 'quizes__block'
										}
										key={index}
										onClick={() => setSelect(index)}
									>
										<h3 className="quizes__header">{elem.header}</h3>
										<p className="quizes__value">{elem.value}</p>
									</div>
								)
							})
						} else {
							props.state.questions.choises[11].selectors.map((elem, index) => {
								return (
									<div
										className={
											select === index
												? 'quizes__block active__quiz'
												: 'quizes__block'
										}
										key={index}
										onClick={() => setSelect(index)}
									>
										<h3 className="quizes__header">{elem.header}</h3>
										<p className="quizes__value">{elem.value}</p>
									</div>
								)
							})
						}
					} else {
						if (props.state.questions.choises[0] === 'female') {
							props.state.questions.choises[5].selectors.map((elem, index) => {
								return (
									<div
										className={
											select === index
												? 'quizes__block active__quiz'
												: 'quizes__block'
										}
										key={index}
										onClick={() => setSelect(index)}
									>
										<h3 className="quizes__header">{elem.header}</h3>
										<p className="quizes__value">{elem.value}</p>
									</div>
								)
							})
						} else {
							props.state.questions.choises[11].selectors.map((elem, index) => {
								return (
									<div
										className={
											select === index
												? 'quizes__block active__quiz'
												: 'quizes__block'
										}
										key={index}
										onClick={() => setSelect(index)}
									>
										<h3 className="quizes__header">{elem.header}</h3>
										<p className="quizes__value">{elem.value}</p>
									</div>
								)
							})
						}
					}
				}}
			</div>
			<div className="question__control-name">
				<Link
					to={{
						pathname: `/questions/${
							parseInt(props.match.params.questionid) + 1
						}`,
					}}
					className={
						disabled
							? 'control__button-name control__button-name-disabled control__button-name-danger quiz__button'
							: 'control__button-name quiz__button'
					}
					onClick={() =>
						props.setSelect({
							id: props.match.params.questionid - 1,
							value: select,
						})
					}
				>
					Confirm
				</Link>
			</div>
		</>
	)
}

export default connect(
	state => ({ state }),
	dispatch => ({
		setSelect: payload => {
			dispatch({ type: 'SET_QUIZ_SELECT', payload })
		},
	}),
)(withRouter(CharacterQuiz))
