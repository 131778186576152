import React, { useState, useEffect } from 'react'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import './NameInput.scss'

function NameInput(props) {
	const [input, setInput] = useState('')
	const [disabled, setDisabled] = useState(true)
	useEffect(() => {
		if (input === '') {
			setDisabled(true)
		} else {
			setDisabled(false)
		}
	})

	return (
		<div className="namebox">
			<h4 className="namebox__text">
				{props.state.questions.choises[props.match.params.questionid - 1].text}
			</h4>
			<input
				className="namebox__input"
				type="text"
				placeholder="Your name"
				value={input}
				onChange={e => setInput(e.target.value)}
			/>
			<div className="question__control-name">
				<Link
					to={{
						pathname: `/questions/${
							parseInt(props.match.params.questionid) + 1
						}`,
					}}
					className={
						disabled
							? 'control__button-name control__button-name-disabled control__button-name-danger'
							: 'control__button-name'
					}
					onClick={() =>
						props.setName({
							id: props.match.params.questionid - 1,
							name: input,
						})
					}
				>
					Confirm
				</Link>
			</div>
		</div>
	)
}

export default connect(
	state => ({ state }),
	dispatch => ({
		setName: payload => {
			dispatch({ type: 'SET_NAME', payload })
		},
	}),
)(withRouter(NameInput))
