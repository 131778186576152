import React from 'react'

import './styles.scss'

const Hero = ({ character, type, isShowHero, emotion }) => {
	return (
		<div
			className={`hero ${
				type === 'Left' && isShowHero
					? 'hero--left-show'
					: type === 'Left' && !isShowHero
					? 'hero--left-hide'
					: type === 'Right' && isShowHero
					? 'hero--right-show'
					: 'hero--right-hide'
			}`}
		>
			<img
				src={`/img/story/${character}/${emotion}.png`}
				alt="main-hero"
				className="hero__image"
			/>
		</div>
	)
}

export { Hero }
