import { withRouter } from 'react-router'
import {
	Header,
	SliderAssets,
	NameInput,
	AnotherPerson,
	CharacterQuiz,
	BackgroundSelector,
	BackgroundAndTitleSelector,
	BookSelector,
	MarriageAnswer,
	TaskBox,
} from '../exporter'
import { connect } from 'react-redux'
import { Redirect } from 'react-router-dom'
import './QuestionController.scss'
import MArriageQuestion from '../MarriageQuestion/MarriageQuestion'
import { useEffect } from 'react'
import { ampEventWithEventProperty } from '../../utils/amplitude'

function QuestionComponent(props) {
	useEffect(() => {
		if (props.match.params.questionid - 2 !== 0) {
			ampEventWithEventProperty('question_answered', {
				question: props.match.params.questionid - 1,
			})
		}
	}, [props.match.params.questionid])

	if (props.state.questions.choises.length < props.match.params.questionid) {
		return <Redirect to="/processing" />
	} else {
		switch (
			props.state.questions.choises[props.match.params.questionid - 1]
				.componentType
		) {
			case 'SLIDER_ASSETS': {
				return (
					<div className="w-100 questioncomponent">
						<Header />
						<SliderAssets />
					</div>
				)
			}
			case 'NAME_INPUT': {
				return (
					<div className="w-100 questioncomponent">
						<Header />
						<NameInput />
					</div>
				)
			}
			case 'SECOND_PERSON_ASSETS': {
				return (
					<div className="w-100 questioncomponent">
						<Header />
						<AnotherPerson />
					</div>
				)
			}
			case 'CHARACTER_QUIZ': {
				return (
					<div className="w-100 questioncomponent">
						<Header />
						<CharacterQuiz />
					</div>
				)
			}
			case 'BACKGROUND_SELECT': {
				return (
					<>
						<BackgroundSelector />
					</>
				)
			}
			case 'BACKGROUND_AND_TITLE_SELECT': {
				return (
					<>
						<BackgroundAndTitleSelector />
					</>
				)
			}
			case 'BOOK_SELECT': {
				return (
					<>
						<Header />
						<BookSelector />
					</>
				)
			}
			case 'MARRIAGE_QUESTION': {
				return (
					<>
						<Header />
						<TaskBox />
						<MArriageQuestion />
					</>
				)
			}
			case 'MARRIAGE_ANSWER': {
				return (
					<>
						<Header />
						<TaskBox />
						<MarriageAnswer />
					</>
				)
			}
			// case 'PHONE': {
			// 	// return <Epilog />
			// 	return history.push('/')
			// }
			default: {
				return <h1>Hello</h1>
			}
		}
	}
}

export default connect(
	state => ({ state }),
	dispatch => ({
		getSelect: payload => {
			dispatch({ type: 'SET_ANSWER', payload })
		},
	}),
)(withRouter(QuestionComponent))
