import React from 'react'
import { Header } from '../exporter'
import { connect } from 'react-redux'
import { withRouter } from 'react-router'
import { Link } from 'react-router-dom'
import './Epilog.scss'

function Epilog(props) {
	const library = [
		`The book is "Make Me Yours"`,
		`The book is “Between Ice and Fire”`,
		`The book is “Love Me In Danger”`,
		`The book is “A Billionnaire’s Wager”`,
	]
	return (
		<>
			<Header />
			<p className="epilog__text">
				{library[props.state.questions.choises[19].answer - 1 || 0]}
			</p>
			<div className="epilog__phone" style={{ width: '60%' }}>
				<img
					src={`/assets/epilog/epilog${
						props.state.questions.choises[19].answer || 0
					}.png`}
					style={{ width: '100%' }}
				/>
				<Link
					to={{ pathname: `/personality` }}
					className="control__button"
					onClick={() =>
						props.getAnswer({ id: props.match.params.questionid - 1 })
					}
					style={{ width: 216, margin: 0, bottom: 'auto', marginTop: '100%' }}
				>
					Get my own story
				</Link>
			</div>
		</>
	)
}
export default connect(
	state => ({ state }),
	dispatch => ({
		getAnswer: payload => {
			dispatch({ type: 'SET_ANSWER', payload })
		},
	}),
)(withRouter(Epilog))
