import React from 'react'
import { Link } from 'react-router-dom'

import './styles.scss'

const Footer = ({ flow }) => {
	return (
		<div className="sale-footer">
			<img
				src="/img/sale/pay-safe-secure.png"
				alt="sale-footer-logos"
				className="sale-footer__logos"
			/>
			<div className="sale-footer-links">
				<a
					href="https://mail.google.com/mail/?view=cm&fs=1&to=support@my-fantasyapp.com"
					className="link"
					target="_blank"
					rel="noreferrer"
				>
					Contact Us
				</a>
				<a href="/privacy-policy" className="link">
					Privacy Policy
				</a>
				<a href="/subscription-terms" className="link">
					Terms of Service
				</a>
			</div>

			<div className="money-back">
				<h3 className="money-back__title">Money-back guarantee</h3>
				<p className="money-back__description">
					<span className="money-back__description-bold">14-day money back guarantee</span>, which entitles you to a full refund upon
					cancellation and emailing{' '}
					<a
						href="https://mail.google.com/mail/?view=cm&fs=1&to=support@my-fantasyapp.com"
						target="_blank"
						rel="noreferrer"
					>
						support@my-fantasyapp.com
					</a>{' '}
					to request a refund. Such refund requests must be made within the
					first 14 calendar days from your first date of payment. Find more
					about applicable limitations in our <br /> {' '}
					<Link 
						className="link"
						to={{
							pathname: '/money-back'
						}}
					>
						Money-back Guarantee Policy
					</Link>
					.
				</p>
				<img
					src="/img/sale/ribbon-money-back.png"
					alt="money-back-ribbon"
					className="money-back__ribbon"
				/>
			</div>

			<span className="sale-footer__adress">
				GM Unicorn Corporation Limited Florinis 7, Greg Tower,2nd Floor, 1065,
				Nicosia, Cyprus
			</span>
		</div>
	)
}

export { Footer }
