import React from 'react'
import ReactDOM from 'react-dom'
import './index.css'
import './styles/styles.scss'
import App from './App'
import { BrowserRouter } from 'react-router-dom'
import { Provider } from 'react-redux'
import { PersistGate } from 'redux-persist/integration/react'
import { persistor, store } from './redux/store'
import HeaderProvider from './components/hoc/HeaderProvider'

ReactDOM.render(
	<BrowserRouter>
		<Provider store={store}>
			<PersistGate loading={null} persistor={persistor}>
				<React.StrictMode>
					<HeaderProvider>
						<App />
					</HeaderProvider>
				</React.StrictMode>
			</PersistGate>
		</Provider>
	</BrowserRouter>,
	document.getElementById('root'),
)
