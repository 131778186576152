import React from 'react'
import { useSelector } from 'react-redux'
import { Redirect, useLocation } from 'react-router'

import './styles.scss'

const SuccessScreen = () => {
	const { search } = useLocation()
	const { bill } = useSelector(state => state.user)

	const queryParams = new URLSearchParams(search)

	return !(bill || queryParams.get('billed')) ? (
		<Redirect to="/questions/2" />
	) : (
		<div className="success-screen">
			<img
				className="success-screen__image"
				src="/img/success/my-fantasy.png"
				alt="my-fantasy"
			/>
			<h4 className="success-screen__title">Success!</h4>
			<p className="success-screen__description">
				You&apos;ve got premium access to app.
				<br />
				Download right now!
			</p>
			<h4 className="success-screen__subtitle">Instruction</h4>
			<div className="success-screen-step">
				<p className="step__title">Step 1</p>
				<p className="step__description">
					Follow by the link to get My Fantasy app
				</p>
			</div>
			<div className="download-links">
				<a
					href="https://apps.apple.com/us/app/my-fantasy-make-your-story/id1491717191"
					className="link"
					target="_blank"
					rel="noreferrer"
				>
					<img
						className="link-image"
						src="/img/success/download-apple.png"
						alt="download-apple"
					/>
				</a>
				<a
					href="https://play.google.com/store/apps/details?id=gmem.episode&hl=en_US&gl=US"
					className="link"
					target="_blank"
					rel="noreferrer"
				>
					<img
						className="link-image"
						src="/img/success/download-google.png"
						alt="download-google"
					/>
				</a>
			</div>
			<div className="success-screen-step">
				<p className="step__title">Step 2</p>
				<p className="step__description">Next step is your activation key</p>
				<img src="/img/success/step2.png" className="step__image" alt="step" />
			</div>
		</div>
	)
}

export { SuccessScreen }
