export const offersList = [
	{
		id: 0,
		trial: '1-Week',
		pricePerWeek: '$9,99 for a one-week',
		fullPrice: '$9,99',
		price: 9.99,
		priceImage: '/img/sale/offer-price1.png',
		product_id: process.env.REACT_APP_PRODUCT_ID_ONE_WEEK,
		isBestler: false,
		isSelected: false,
	},
	{
		id: 1,
		trial: '1-month',
		pricePerWeek: '$19,99 for a one-month',
		fullPrice: '$19,99',
		price: 19.99,
		priceImage: '/img/sale/offer-price2.png',
		product_id: process.env.REACT_APP_PRODUCT_ID_ONE_MONTH,
		isBestler: true,
		isSelected: true,
	},
	{
		id: 2,
		trial: '3-month',
		pricePerWeek: '$29,99 for a three-month',
		fullPrice: '$29,99',
		price: 29.99,
		priceImage: '/img/sale/offer-price3.png',
		product_id: process.env.REACT_APP_PRODUCT_ID_THREE_MONTH,
		isBestler: false,
		isSelected: false,
	},
]
