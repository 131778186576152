import React, { useEffect, useState } from 'react'

import formatTimeString from '../../../utils/formatTimeString'
import { Button } from '../../common'

import './styles.scss'

const TIMER_SECONDS_AMOUNT = 600
const TIMEOUT_TICK_DELAY = 1000

const Offer = ({ onBtnClick }) => {
	const [timerId, setTimerId] = useState(null)
	const [secondsLeft, setSecondsLeft] = useState(TIMER_SECONDS_AMOUNT)

	const timerValue = formatTimeString(secondsLeft)

	useEffect(() => {
		const timer = window.setInterval(() => {
			setSecondsLeft(secondsLeft => secondsLeft - 1)
		}, TIMEOUT_TICK_DELAY)

		setTimerId(timer)
		return () => clearInterval(timer)
	}, [])

	if (secondsLeft === 0 && timerId) {
		setSecondsLeft(TIMER_SECONDS_AMOUNT)
	}

	return (
		<div className="discount-offer">
			<img
				src="/img/sale/logo.png"
				alt="my-fantasy-logo"
				className="discount-offer__logo"
			/>
			<div className="discount-offer-info">
				<h5 className="discount-offer-info__title">
					Hey, babe! Where are you going? Take your offer
				</h5>
				<p className="discount-offer-info__price">$ 0,00</p>
				<div className="timer-container">
					<span className="timer__label">Offer expires:</span>
					<span className="timer">{timerValue}</span>
				</div>
			</div>
			<div className="discount-offer-buy">
				<Button label="Try for Free" width={256} onClick={onBtnClick} />
				<span className="discount-offer-buy__description">
					No charges for 3 days
					<br />
					Cancel anytime · $6.99 billed weekly
				</span>
			</div>
		</div>
	)
}

export { Offer }
