import amplitude from 'amplitude-js'

const ampInst = amplitude.getInstance()
ampInst.init('aad23c3ba92dfa0e1091e6d002673c9d')

const ampEvent = event => {
	ampInst.logEvent(event)
}
const ampEventWithEventProperty = (event, props) => {
	ampInst.logEvent(event, props)
}
const ampSetUserProperty = (key, value) => {
	const identify = new amplitude.Identify().set(key, value)
	ampInst.identify(identify)
}

export { ampEvent, ampEventWithEventProperty, ampSetUserProperty }
