export const payPalRequest = async body => {
	let data = await fetch(
		`${process.env.REACT_APP_SERVER_URL}/api/paypal/myfantasy/subscription`,
		{
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
				auth: process.env.REACT_APP_PAYPAL_PUBLIC_KEY,
			},
			body: JSON.stringify(body),
		},
	)
	data = await data.json()
	return data
}
export const cardRequest = async body => {
	let data = await fetch(
		`${process.env.REACT_APP_SERVER_URL}/api/card/v2.0/myfantasy/subscription`,
		{
			method: 'POST',
			headers: {
				'Content-type': 'application/json',
				auth: process.env.REACT_APP_CARD_PUBLIC_KEY,
			},
			body: JSON.stringify(body),
		},
	)
	data = await data.json()
	return data
}
