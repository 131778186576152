import React, { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Switch, Redirect, Route, useLocation, useHistory } from 'react-router'

import { Home, QuestionController } from './components/exporter'
import { setFlow } from './redux/actions/user'
import DiscountScreen from './screens/discount'
import EmailScreen from './screens/email'
import ErrorScreen from './screens/error'
import ProcessingScreen from './screens/processing'
import SaleScreen from './screens/sale'
import StoryScreen from './screens/story'
import AccountScreenContainer from './screens/AccountScreen/container/AccountScreenContainer'
import SuccessScreen from './screens/success'
import PDFViewer from './components/PDFViewer'
import { ampEvent } from './utils/amplitude'

const Router = ({ type }) => {
	const history = useHistory()
	const dispatch = useDispatch()
	const { pathname } = useLocation()
	const { flow } = useSelector(state => state.user)

	useEffect(() => {
		if (pathname.includes('2') && pathname.includes('questions')) {
			setTimeout(() => ampEvent('onboarding_shown'), 0)
		}
	}, [pathname])

	const getFlowRouting = () => {
		switch (flow) {
			case '22': {
				// story
				return (
					<>
						<Route path="/story/:id" component={StoryScreen} exact />
						<Redirect to="/story/2" />
					</>
				)
			}
			// quiz
			case '11':
			default: {
				return (
					<>
						<Route
							path="/questions/:questionid"
							component={() => (
								<div
									className="App background1"
									style={{
										zoom:
											window.innerWidth / 375 > 1 ? 1 : window.innerWidth / 375,
									}}
								>
									<QuestionController />
								</div>
							)}
						/>
						{/* <Redirect to="/questions/2" /> */}
					</>
				)
			}
		}
	}

	useEffect(() => {
		if (pathname === '/') {
			if (type) {
				dispatch(setFlow({ flow: type }))

				if (type === '22') {
					return history.push('/story/2')
				}
			}
			return history.push('/questions/2')
		}
	}, [])

	return (
		<Switch>
			<Route
				path="/"
				component={() => (
					<div
						className="App background1"
						style={{
							zoom: window.innerWidth / 375 > 1 ? 1 : window.innerWidth / 375,
						}}
					>
						<Home />
					</div>
				)}
				exact
			/>
			<Route path="/processing" component={ProcessingScreen} exact />
			<Route path="/email" component={EmailScreen} exact />
			<Route path="/sale" component={SaleScreen} />
			<Route path="/discount" component={DiscountScreen} />
			<Route path="/success" component={SuccessScreen} />
			<Route path="/error" component={ErrorScreen} />
			<Route path="/account" component={AccountScreenContainer} />
			<Route
				path="/privacy-policy"
				component={() => (
					<PDFViewer url="/pdf/MyFantasy - Privacy Policy - Website - November 2020.pdf" />
				)}
			/>
			<Route
				path="/terms-of-use"
				component={() => (
					<PDFViewer url="/pdf/MyFantasy - Terms of Use - Web2App - April 2021.pdf" />
				)}
			/>
			<Route
				path="/subscription-terms"
				component={() => (
					<PDFViewer url="/pdf/MyFantasy (App+Web) - General Subscription Terms - free trial.pdf" />
				)}
			/>
			<Route
				path="/money-back"
				component={() => (
					<PDFViewer url="/pdf/MONEY-BACK POLICY MyFantasy.pdf" />
				)}
			/>
			<Route
				path="/faq"
				component={() => <PDFViewer url="/pdf/MyFantasy - FAQ.pdf" />}
			/>
			{getFlowRouting()}
		</Switch>
	)
}

export default Router
