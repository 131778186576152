import React from 'react'
import { Button } from '../../../../components/common/button'
import cancelSubscriptionEmailTemplate from '../../../../constants/cancelSubscriptionEmailTemplate'
import { ampEvent } from '../../../../utils/amplitude'

import './styles.scss'

const AuthorizedUserInfo = () => {
	return (
		<div className="authorized-info">
			<p className="authorized-info__description">
				Your subscription is active but you may cancel it
			</p>
			<a
				href={cancelSubscriptionEmailTemplate}
				className="authorized-info__link"
				target="_blank"
				rel="noreferrer"
				onClick={() => {
					ampEvent('cancel_subscription')
				}}
			>
				<Button width={335} label="Cancel subscription" />
			</a>
		</div>
	)
}

export default AuthorizedUserInfo
